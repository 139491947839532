import React, { useContext, useState } from 'react'

export type ConnectWalletMessagePopupContent = {
    isShowing: boolean,
    show: (popupContent: boolean) => void
}

const ConnectWalletMessagePopupContext = React.createContext<ConnectWalletMessagePopupContent | undefined>(undefined)

export function useConnectWalletMessagePopupContext() {
    return useContext(ConnectWalletMessagePopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function ConnectWalletMessagePopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<ConnectWalletMessagePopupContent>({
        isShowing: false,
        show: (shouldShow: boolean) => {
            setPopupContent(
                {...popupContent, isShowing: shouldShow}
            )
        }
    })

    return (
        <ConnectWalletMessagePopupContext.Provider value={popupContent}>
            {props.children}
        </ConnectWalletMessagePopupContext.Provider>
    )
}

export default ConnectWalletMessagePopupContextProvider
