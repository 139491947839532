import React from 'react'
import styles from './ProbabilityPopup.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import GenericButton from '../../common/genericButton/GenericButton'
import { useProbabilityPopupContext } from '../../contexts/popups/ProbabilityPopupContextProvider'

function ProbabilityPopup() {
    const { t, i18n } = useTranslation()
    const probabilityPopupContext = useProbabilityPopupContext()

    const onClose = () => {
        probabilityPopupContext?.show(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.popupWrapper}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.title}> {t<string>('probability.' + probabilityPopupContext?.drawType + '.title')} </div>
                    <div className={styles.description}> {t<string>('probability.' + probabilityPopupContext?.drawType + '.description')} </div>
                        {probabilityPopupContext?.drawType == "pack" && <div className={styles.content}>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t<string>('probability.' + probabilityPopupContext?.drawType + '.card1')}</th>
                                        <th>{t<string>('probability.' + probabilityPopupContext?.drawType + '.card2')}</th>
                                        <th>{t<string>('probability.' + probabilityPopupContext?.drawType + '.card3')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>{t<string>('probability.legendary')}</th>
                                        <td>0.0%</td>
                                        <td>0.0%</td>
                                        <td>2.5%</td>
                                    </tr>
                                    <tr>
                                        <th>{t<string>('probability.epic')}</th>
                                        <td>2.5%</td>
                                        <td>2.5%</td>
                                        <td>2.5%</td>
                                    </tr>
                                    <tr>
                                        <th>{t<string>('probability.rare')}</th>
                                        <td>10.0%</td>
                                        <td>10.0%</td>
                                        <td>95.0%</td>
                                    </tr>
                                    <tr>
                                        <th>{t<string>('probability.common')}</th>
                                        <td>87.5%</td>
                                        <td>87.5%</td>
                                        <td>0.0%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                    {probabilityPopupContext?.drawType == "single" && <div className={styles.content}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>{t<string>('probability.epic')}</th>
                                    <td>4.0%</td>
                                </tr>
                                <tr>
                                    <th>{t<string>('probability.rare')}</th>
                                    <td>48.0%</td>
                                </tr>
                                <tr>
                                    <th>{t<string>('probability.common')}</th>
                                    <td>48.0%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ProbabilityPopup