import React, { useContext, useState } from 'react'

export type MessagePopupContent = {
    isShowing: boolean,
    title: string,
    description: string,
    show: (popupContent: boolean, title?: string, description?: string) => void
}

const MessagePopupContext = React.createContext<MessagePopupContent | undefined>(undefined)

export function useMessagePopupContext() {
    return useContext(MessagePopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function MessagePopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<MessagePopupContent>({
        isShowing: false,
        title: "",
        description: "",
        show: (shouldShow: boolean, title: string = "", description: string = "") => {
            setPopupContent(
                {...popupContent, isShowing: shouldShow, title: title, description: description}
            )
        }
    })

    return (
        <MessagePopupContext.Provider value={popupContent}>
            {props.children}
        </MessagePopupContext.Provider>
    )
}

export default MessagePopupContextProvider
