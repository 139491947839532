import React from 'react'
import styles from './HowTo.module.scss'
import alertCircle from "../../images/alert-circle.png"

function HowTo({text, link} : {text: string, link:string}) {
    return (
        <div className={styles.howToBid} onClick={() => { window.open(link) }}>
            <img className={styles.logo} src={alertCircle} />
            {text}
        </div>
    )
}

export default HowTo
