import React from 'react'
import { Trans } from 'react-i18next'
import styles from './ProgressBar.module.scss'

function ProgressBar({step} : {step : number}) {

    return (
            <div className={styles.progressBar}>
                <div className={styles.slotWrapper}>
                    {step >= 0 ?
                    <div className={styles.slotOn}>
                        <div className={styles.step}>1</div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={styles.step}>1</div>
                    </div>}
                    <div className={styles.slotTitle}>
                        <Trans i18nKey='drawItemPopup.steps.step1'></Trans>
                    </div>
                </div>
                {step >= 1 ?
                <div className={styles.progressLineOn}>
                </div> :
                <div className={styles.progressLineOff}>
                </div>
                }
                <div className={styles.slotWrapper}>
                    {step >= 1 ?
                    <div className={styles.slotOn}>
                        <div className={styles.step}>2</div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={styles.step}>2</div>
                    </div>}
                    <div className={styles.slotTitle}>
                        <Trans i18nKey='drawItemPopup.steps.step2'></Trans>
                    </div>
                </div>
                {step >= 2 ?
                <div className={styles.progressLineOn}>
                </div> :
                <div className={styles.progressLineOff}>
                </div>
                }
                <div className={styles.slotWrapper}>
                {step >= 2 ?
                    <div className={styles.slotOn}>
                        <div className={styles.step}>3</div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={styles.step}>3</div>
                    </div>}
                    <div className={styles.slotTitle}>
                        <Trans i18nKey='drawItemPopup.steps.step3'></Trans>
                    </div>
                </div>
                {step >= 3 ?
                <div className={styles.progressLineOn}>
                </div> :
                <div className={styles.progressLineOff}>
                </div>
                }
                <div className={styles.slotWrapper}>
                {step >= 3 ?
                    <div className={styles.slotOn}>
                        <div className={styles.step}>4</div>
                    </div> :
                    <div className={styles.slotOff}>
                        <div className={styles.step}>4</div>
                    </div>}
                    <div className={styles.slotTitle}>
                        <Trans i18nKey='drawItemPopup.steps.step4'></Trans>
                    </div>
                </div>
            </div>
    )
}

export default ProgressBar
