import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import styles from './DrawItemSection.module.scss'
import drawItemImg from '../images/Banner_Pack_Series1.png'
import player1Img from "../../../images/pack1/PlayerThumbnail_RCFC_1.png"
import player4Img from "../../../images/pack1/PlayerThumbnail_RCFC_4.png"
import player10Img from "../../../images/pack1/PlayerThumbnail_RCFC_10.png"
import player14Img from "../../../images/pack1/PlayerThumbnail_RCFC_14.png"
import player23Img from "../../../images/pack1/PlayerThumbnail_RCFC_23.png"
import player27Img from "../../../images/pack1/PlayerThumbnail_RCFC_27.png"
import player22Img from "../../../images/pack1/PlayerThumbnail_RCFC_22.png"
import { isMobile } from 'react-device-detect'
import GenericButton from '../../../common/genericButton/GenericButton'
import {IsExpired} from '../../../common/dateCounter/DateCounter'
import {draw_start_month, draw_start_day, draw_start_hour, draw_start_minute, drawEnabled } from '../../../AppSettings.json'

function DrawItemSection() {
    const history = useHistory()
    const gotoDrawItems = () => {
        history.push("/drawitem")
    }

    const {t, i18n} = useTranslation()
    const [ isStarted , setIsStarted ] = useState<boolean>(false)

    useEffect(() => {
        setInterval(() => { 
            let started = IsExpired( {year:2021,  month:draw_start_month-1, day:draw_start_day , hour:draw_start_hour,  minute:draw_start_minute , second:0} );
            setIsStarted(started);  
        } , 1000 ) 
    } , [] )
    
    return (
        <div className={styles.bgWrapper}>
            <div className={styles.wrapper}>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleSection}>
                        <div className={styles.clubTitle}><Trans i18nKey='drawItemSection.clubTitle'></Trans></div>
                        <div className={styles.itemTitle}><Trans i18nKey='drawItemSection.itemTitle'></Trans></div>
                    </div>
                    <div className={styles.description}>
                        <Trans i18nKey='drawItemSection.description'></Trans>
                    </div>
                    <div className={styles.memberSection}>
                        <div className={styles.memberTitle}>
                        <Trans i18nKey='drawItemSection.membersTitle'></Trans>
                        </div>
                        <div className={styles.memberIconSection}>
                            <div className={styles.memberIconRow}>
                                <div className={styles.memberIcon}><img src={player1Img} /></div>
                                <div className={styles.memberIcon}><img src={player4Img} /></div>
                                <div className={styles.memberIcon}><img src={player10Img} /></div>
                                <div className={styles.memberIcon}><img src={player14Img} /></div>
                            </div>
                            <div className={styles.memberIconRow}>
                                <div className={styles.memberIcon}><img src={player23Img} /></div>
                                <div className={styles.memberIcon}><img src={player27Img} /></div>
                                <div className={styles.memberIcon}><img src={player22Img} /></div>
                            </div>
                        </div>
                    </div>
                    {!isMobile && <div className={styles.ctaButtonWrapper}>
                        <GenericButton enabled={drawEnabled && isStarted } value={t<string>('drawItemSection.ctaButton.title')} onClick={()=>{ gotoDrawItems() }} />
                    </div>}
                </div>
                <div className={styles.imageWrapper}>
                    <img className={styles.drawItemImage}  src={drawItemImg}/>
                </div>
            </div>
        </div>
    )
}

export default DrawItemSection