import React, { useContext, useState } from 'react'

export type ProbabilityPopupContent = {
    isShowing: boolean,
    drawType : string,
    show: (popupContent: boolean, drawType? : string ) => void
}

const ProbabilityPopupContext = React.createContext<ProbabilityPopupContent | undefined>(undefined)

export function useProbabilityPopupContext() {
    return useContext(ProbabilityPopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function ProbabilityPopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<ProbabilityPopupContent>({
        isShowing: false,
        drawType : "single",
        show: (shouldShow: boolean, drawType: string = "single") => {
            setPopupContent(
                {...popupContent, isShowing: shouldShow, drawType: drawType}
            )
        }
    })

    return (
        <ProbabilityPopupContext.Provider value={popupContent}>
            {props.children}
        </ProbabilityPopupContext.Provider>
    )
}

export default ProbabilityPopupContextProvider
