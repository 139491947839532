import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import GenericButton from '../../common/genericButton/GenericButton'
import styles from './PlaceBidPopup.module.scss'
import wethIcon from '../../images/weth-icon.png'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { WyvernSchemaName } from 'opensea-js/lib/types'
import { useLoadingPopupContext } from '../../contexts/popups/LoadingPopupContextProvider'
import { useMessagePopupContext } from '../../contexts/popups/MessagePopupContextProvider'
import { usePlaceBidPopupContext } from '../../contexts/popups/PlaceBidPopupContextProvider'
import { useWrapEthPopupContext } from '../../contexts/popups/WrapEthPopupContextProvider'
import Loading from '../../common/loading/Loading'
import Terms from '../../common/termsAndConditions/termsAndConditions'
import HowTo from '../../common/howTo/HowTo'
import {env, assetAddressMainnet, assetAddressRinkeby, auctionIdMainnet, auctionIdRinkeby } from '../../secrets.json'


function PlaceBidPopup() {
    enum Progress {
        Idle,
        Transaction,
        TransactionInProgress,
        TransactionDone,
        TransactionFailed
    }

    const { t, i18n } = useTranslation()
    const appContext = useAppContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const loadingPopupContext = useLoadingPopupContext()
    const messagePopupContext = useMessagePopupContext()
    const wrapEthPopupContext = useWrapEthPopupContext()
    const placeBidPopupContext = usePlaceBidPopupContext()
    const openSeaContext = useOpenSeaContext()
    const [bidPrice, setBidPrice] = useState(0)
    const [termsChecked, setTermsChecked] = useState(false)
    const [progress, setProgress] = useState<Progress>(Progress.Idle)
    const [doneMsg, setDoneMsg] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {

        return () => {

        }
    }, [])

    function sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    const reset = () => {
        setProgress(Progress.Idle)
        setTermsChecked(false)
        setBidPrice(0)
    }

    const onChangeValue = async (e: any) => {
        console.log(e.target.value)
        setBidPrice(e.target.value)
    }

    const onClickWrapEth = () => {
        reset()
        placeBidPopupContext?.show(false)
        wrapEthPopupContext?.show(true)
    }

    const onClose = () => {
        placeBidPopupContext?.show(false)
    }

    const placeBid = async () => {
        if (!appContext?.accounts) {
            connectWalletPopupContext?.show(true)
            return
        }

        setProgress(Progress.TransactionInProgress)
        try {
            var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
            var tokenId = isEnvMainnet? auctionIdMainnet : auctionIdRinkeby;
            var asset = await openSeaContext?.api.getAsset({
                "tokenAddress": tokenAddress,
                "tokenId": tokenId
            })
            if (asset) {
                // console.log("sellorder " + JSON.stringify(asset, null, 2))
                if (asset.sellOrders) {
                    var result = await openSeaContext?.createBuyOrder({
                        asset: {
                            tokenAddress: asset.tokenAddress,
                            tokenId: asset.tokenId,
                            schemaName: WyvernSchemaName.ERC721
                        },
                        accountAddress: appContext?.accounts[0],
                        startAmount: bidPrice
                    })
                    if (result?.hash) {
                        setDoneMsg(result?.hash)
                    }
                    console.log(JSON.stringify(result, null, 2))
                    setProgress(Progress.TransactionDone)
                }
            } else {
                setErrorMsg(t<string>('placeBidPopup.error.assetNotFound'))
                setProgress(Progress.TransactionFailed)
            }

        } catch (e) {
            console.log(JSON.stringify(e, null, 2))
            console.log("asset" , asset);
            setErrorMsg(t<string>('placeBidPopup.error.placeBidRunTimeError'))
            setProgress(Progress.TransactionFailed)
        } finally {
        }
    }

    return (
        <div className={styles.bgWrapper}>
            {progress == Progress.Idle && <div className={styles.idlePopupWrapper}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.progressBid}>
                        <div className={styles.title}>
                            <Trans i18nKey="placeBidPopup.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="placeBidPopup.description"></Trans>
                        </div>
                        <div className={styles.priceSection}>
                            <div className={styles.priceWrapper}>

                                <div className={styles.priceIconWrapper}>
                                    <div className={styles.priceTitle}><Trans i18nKey="placeBidPopup.price"></Trans></div>
                                    <div className={styles.priceIcon}>
                                    <img src={wethIcon}/>
                                    <div className={styles.iconName}>WETH</div>
                                    </div>
                                </div>
                                <input className={styles.wethInput} type="number" onChange={(e) => {onChangeValue(e)}}/>
                            </div>
                        </div>
                        <div className={styles.termsSection}>
                            <input className={styles.checkBox} type="checkBox" onClick={()=>{ setTermsChecked(!termsChecked) }}/> 
                            <Terms text={t<string>('placeBidPopup.terms')} />
                        </div>
                        <div className={styles.ctaSection}>
                            <div className={styles.placeBid}><GenericButton value={t<string>('placeBidPopup.ctaButton.placeBid')} onClick={()=>{ placeBid() }} enabled={termsChecked} /></div>
                            {/*<div className={styles.wrapEth}><GenericButton  value={t<string>('placeBidPopup.ctaButton.wrapEth')} onClick={()=>{ placeBid() }} disabled={!termsChecked} /></div>*/}
                        </div>
                        <div className={styles.howToBidSection}>
                            <HowTo text={t<string>('bidItemSection.howToBid')} link={t<string>('mediumLinks.howToBuy')}/>
                        </div>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionInProgress && <div className={styles.inProgress}>
                <div className={styles.popupWrapper}>
                    <div className={styles.closeButtonWrapper}>
                        <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div className={styles.title}>
                            <Trans i18nKey="placeBidPopup.transactionInProgress.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            {errorMsg}
                        </div>
                        <Loading width={128} height={128}/>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionDone && <div className={styles.transactionDone}>
                <div className={styles.popupWrapper}>
                    <div className={styles.closeButtonWrapper}>
                        <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div className={styles.title}>
                            <Trans i18nKey="placeBidPopup.transactionDone.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="placeBidPopup.transactionDone.description"></Trans> 
                            <div className={styles.hash}> {doneMsg} </div>
                        </div>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionFailed && <div className={styles.progressFailed}>
                <div className={styles.popupWrapper}>
                    <div className={styles.closeButtonWrapper}>
                        <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div className={styles.title}>
                            <Trans i18nKey="placeBidPopup.transactionFailed.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            {errorMsg}
                        </div>
                        <div className={styles.ctaSection}>
                            <div className={styles.tryAgain}><GenericButton value={t<string>('placeBidPopup.ctaButton.tryAgain')} onClick={()=>{ reset() }}/></div>
                            <div className={styles.wrapEth}><GenericButton value={t<string>('placeBidPopup.ctaButton.wrapEth')} onClick={()=>{ onClickWrapEth() }}/></div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default PlaceBidPopup
