import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppContext } from '../../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../../contexts/OpenSeaContextProvider'
import { usePopupContext, usePopupUpdateContext } from '../../../contexts/PopupContextProvider'
import styles from './Draw3ItemsPopup.module.scss'
import ProgressBar from '../../../common/progressBar/ProgressBar'
import { useHistory } from 'react-router'
import Loading from '../../../common/loading/Loading'
import DotLoading from '../../../common/dotLoading/DotLoading'
import { OpenSeaAsset, OpenSeaAssetBundle, OpenSeaAssetBundleQuery } from 'opensea-js/lib/types'
import collectionBoxImg from '../images/collection_box.png'
import openBoxMp4 from "../../../images/RCFC_2021_STD_P_S1_OPEN.mp4"
import { useDraw3ItemsPopupContext } from '../../../contexts/popups/Draw3ItemsPopupContextProvider'
import GenericButton from '../../../common/genericButton/GenericButton'
import Price from '../../../common/price/Price'
import HowTo from '../../../common/howTo/HowTo'
import Terms from '../../../common/termsAndConditions/termsAndConditions'
import {singlePrice, packPrice} from '../../../AppSettings.json'
import {env, assetAddressMainnet, assetAddressRinkeby, awsXApiKey , assetOwnerMainnet , assetOwnerRinkeby} from '../../../secrets.json'


function Draw3ItemsPopup() {
    enum Progress {
        Idle,
        Transaction,
        TransactionDone,
        TransactionFailed,
        LuckyDraw,
        LuckyDrawDone,
        LuckyDrawFailed
    }

    const { t, i18n } = useTranslation()
    const openSeaContext = useOpenSeaContext()
    const appContext = useAppContext()
    const draw3ItemsPopupContext = useDraw3ItemsPopupContext()
    const [progress, setProgress] = useState<Progress>(Progress.Idle)
    const [targetBundle, setTargetBundle] = useState<OpenSeaAssetBundle | undefined | null>(undefined)
    const [finalItems, setFinalItems] = useState<any[] | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState<any>("")
    const [termsChecked, setTermsChecked] = useState<boolean>(false)
    const history = useHistory()
    const isEnvMainnet = (env == "mainnet")

    const getFinalItemsDisplay = () => {
        if (finalItems) {
            return (<div className={styles.display}>
                {finalItems.map(finalItem => {
                    if (finalItem.animation_url) {
                        return <div>
                            <video className={styles.finalAnimation} src={finalItem.animation_url} autoPlay={true} loop={true}/>
                            <div>{finalItem.name}</div>
                        </div>
                    } else {
                        return <img className={styles.finalImage} src={finalItem.image}/>
                    }
                })}</div>)
        } else {
            return <div className={styles.loadingWrapper}><Loading width={128} height={128}/></div>
        }
    }

    const drawThree = async () => {
        try {
            setProgress(Progress.Transaction)
            var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
            var bundleMaker = isEnvMainnet? assetOwnerMainnet : assetOwnerRinkeby;
            var openSeaBundlesWithSellOrders : OpenSeaAssetBundle[] = []
            var query : OpenSeaAssetBundleQuery = {
                asset_contract_address: tokenAddress,
            }
            var openSeaResult = await openSeaContext?.api.getBundles(query)
            openSeaResult?.bundles.forEach((bundle) => {
                if (bundle.sellOrders != null && bundle.sellOrders.length > 0) {
                    if ( bundle.maker.address == bundleMaker){
                        openSeaBundlesWithSellOrders.push(bundle)
                    }
                }
            })
            // console.log(" OpenSea bundleItem " + openSeaBundlesWithSellOrders)

            var target : OpenSeaAssetBundle | undefined = undefined
            if (openSeaBundlesWithSellOrders.length == 0) {
                setErrorMsg(t<string>('Error.draw3Items.soldOutError'))
                setProgress(Progress.TransactionFailed)
                return
            }

            var itemsInBundle = undefined
            var updatedDrawData = false
            var draw3Result = await axios.get('https://api.hkfootballnft.com/draw3')

            if (draw3Result.status == 200 && ( draw3Result.data.Items == null || draw3Result.data.Items.length > 0) )
            {
                try {
                    // Update and Retry as opensea have bundle but not server
                    updatedDrawData = true;
                    await axios.get("https://api.hkfootballnft.com/draw3/updatedata")
                    draw3Result = await axios.get('https://api.hkfootballnft.com/draw3')
                } catch(e) {
                    console.log("Error " + JSON.stringify(e))
                    setErrorMsg(t<string>('Error.draw3Items.transactionRuntimeError'))
                    setProgress(Progress.TransactionFailed)
                    return
                }
            }

            if (draw3Result.status == 200 && draw3Result.data.Items && draw3Result.data.Items.length > 0) {
                itemsInBundle = draw3Result.data.Items
                console.log(" -- server bundleItem -- ",itemsInBundle);
            } else {
                setErrorMsg(t<string>('Error.draw3Items.transactionRuntimeError'))
                setProgress(Progress.TransactionFailed)
                return
            }
            
            if (itemsInBundle) {
                var tokenId = itemsInBundle[0].token_id
                for (const openSeaBundle of openSeaBundlesWithSellOrders) {
                    var assets = openSeaBundle.assets;  
                    if (assets.filter( item => item.tokenId == tokenId).length > 0 ) {
                        target = openSeaBundle
                        break
                    }
                }
            }
 
            if (!target) {
                if (!updatedDrawData){
                    // Update draw data as opensea not match server data
                    await axios.get("https://api.hkfootballnft.com/draw3/updatedata")
                }
                console.log("No target yet")
                setErrorMsg(t<string>('Error.draw3Items.soldOutError'))
                setProgress(Progress.TransactionFailed)
                return
            }
            setTargetBundle(target)

        } catch (e) {
            setErrorMsg(t<string>('Error.draw3Items.transactionRuntimeError'))
            setProgress(Progress.TransactionFailed)
            console.log('e ' + JSON.stringify(e))
        }
    }

    useEffect(() => {
        async function onTargetBundleAvailable() {
            if (targetBundle) {
                console.log("On target bundle Available")

                try {
                    if (!(appContext?.accounts && appContext?.accounts.length > 0)) {
                        setErrorMsg(t<string>('Error.connectWalletError'))
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!openSeaContext) {
                        setErrorMsg(t<string>('Error.openSeaError'))
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!(targetBundle.sellOrders && targetBundle.sellOrders.length > 0)) {
                        setErrorMsg(t<string>('Error.noSellOrdersError') + targetBundle.slug)
                        setProgress(Progress.TransactionFailed)
                        luckyDraw()
                        return
                    }

                    console.log("target slug " + JSON.stringify(targetBundle.slug))
                    // console.log(JSON.stringify(targetBundle.assets))

                    var sellOrder = targetBundle.sellOrders[0]
                    var isOrderFulfillable = await openSeaContext.isOrderFulfillable({
                        order: sellOrder,
                        accountAddress: appContext.accounts[0],
                        recipientAddress: appContext.accounts[0]
                    })

                    if (isOrderFulfillable) {
                        var orderResult = await openSeaContext?.fulfillOrder({
                            order: sellOrder,
                            accountAddress: appContext.accounts[0]
                        })
                        console.log("Order Result " + JSON.stringify(orderResult))

                        setProgress(Progress.TransactionDone)
                        luckyDraw()

                    } else {
                        setErrorMsg(t<string>('Error.draw3Items.orderNotFulfillableError') + targetBundle.slug)
                        setProgress(Progress.TransactionFailed)
                    }

                } catch (e) {
                    console.log("Error " + JSON.stringify(e))
                    setErrorMsg(t<string>('Error.draw3Items.transactionRuntimeError') + targetBundle.slug)
                    setProgress(Progress.TransactionFailed)
                    // TODO : remove testing luckydraw
                    luckyDraw()
                }
            }
        }
        onTargetBundleAvailable()
        return () => {

        }
    }, [targetBundle])

    const luckyDraw = async () => {
        console.log('luckyDraw')
        setProgress(Progress.LuckyDraw)
        setTimeout(() => {
            setProgress(Progress.LuckyDrawDone)
        }, 3000)

        try {
            if (targetBundle?.assets) {
                var tmpFinalItems: OpenSeaAsset[] | undefined = []

                for(const asset of targetBundle.assets) {

                    if (isEnvMainnet) {
                        var result = await axios.put("https://api.hkfootballnft.com/updateowner", { "tokenId" : asset.tokenId } , {
                            headers : { 'X-Api-Key' : awsXApiKey }
                        })
                    }
                    else {
                        var result = await axios.put("https://api.hkfootballnft.com/metadata", { "tokenId" : asset.tokenId })
                    }

                    if (result.status == 200) {

                        try {
                            await axios.get("https://api.hkfootballnft.com/draw3/updatedata")
                        } catch(e) {
                            console.log("Refresh Sales Metadata runtime error")
                        }

                        try {
                            var url = isEnvMainnet ? "https://api.opensea.io/api/v1/asset/" : "https://testnets-api.opensea.io/api/v1/asset/"
                            await axios.get(url + asset.tokenAddress + "/" + asset.tokenId + "?force_update=true")
                        } catch(e) {
                            console.log("Refresh OpenSea Metadata runtime error")
                        }

                        try {
                            var metaDataResult = await axios.get("https://api.hkfootballnft.com/json/" + asset.tokenId)

                            if (metaDataResult.status == 200) {
                                tmpFinalItems?.push(metaDataResult.data)
                            } else {
                                setErrorMsg(t<string>("Error.draw3Items.getMetadataAPIError") + asset.tokenId)
                                setProgress(Progress.LuckyDrawFailed)
                            }

                        } catch(e) {
                            setErrorMsg(t<string>("Error.draw3Items.getMetadataRuntimeError"))
                            setProgress(Progress.LuckyDrawFailed)
                        }

                    } else {
                        setErrorMsg(t<string>('Error.draw3Items.uncoverAPIError'))
                        setProgress(Progress.LuckyDrawFailed)
                    }
                }
                console.log('tmpFinalItems ' + JSON.stringify(tmpFinalItems, null, 2))

                setFinalItems(tmpFinalItems)
            } else {
                setErrorMsg(t<string>('Error.draw3Items.luckyDrawTargetBundleError'))
                setProgress(Progress.LuckyDrawFailed)
            }
        } catch(e) {
            setErrorMsg(t<string>('Error.draw3Items.unCoverRuntimeError'))
            setProgress(Progress.LuckyDrawFailed)
        }
    }

    const onClose = () => {
        draw3ItemsPopupContext?.show(false)
    }

    const gotoHome = () => {
        draw3ItemsPopupContext?.show(false)
        history.push("/")
    }

    const gotoCollection = () => {
        draw3ItemsPopupContext?.show(false)
        history.push("/collection")
    }

    const tryAgain = () => {
        console.log('tryAgain')
        draw3ItemsPopupContext?.show(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.closeButtonWrapper}>
                <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
            </div>
            <div id="drawItemPopup" className={styles.popupWrapper}>
                {progress == Progress.Transaction && <div className={styles.progressBarWrapper}><ProgressBar step={0}/></div>}
                {progress == Progress.TransactionDone && <div className={styles.progressBarWrapper}><ProgressBar step={1}/></div>}
                {progress == Progress.LuckyDraw && <div className={styles.progressBarWrapper}><ProgressBar step={2}/></div>}
                {progress == Progress.LuckyDrawDone && <div className={styles.progressBarWrapper}><ProgressBar step={3}/></div>}
                <div className={styles.contentWrapper}>
                    {progress == Progress.Idle && <div className={styles.idleWrapepr}>
                    <div className={styles.descriptionWrapper}>
                        <div className={styles.title}>
                            <Trans i18nKey="draw3ItemsPopup.title"></Trans>
                        </div>
                        <div className={styles.itemImage}><img src={collectionBoxImg}/></div>
                        <div className={styles.name}><Trans i18nKey='draw3ItemsPopup.name'></Trans></div>
                        <div className={styles.line}></div>
                        <div className={styles.price}><Trans i18nKey='draw3ItemsPopup.total'></Trans><Price eth={packPrice}/></div>
                        <div className={styles.terms}><input className={styles.checkbox} type="checkbox" onClick={(value: any) =>{ setTermsChecked(!termsChecked) }}/>
                            <Terms text={t<string>('drawItemPopup.terms')} /></div>
                        <div className={styles.ctaSection}>
                            <GenericButton value={t<string>('draw3ItemsPopup.ctaButton.value')} onClick={()=>{ drawThree() }} enabled={termsChecked} />
                        </div>
                        <div className={styles.howToPurchase}>
                            <HowTo text={t<string>('drawItemPopup.howToPurchase')} link={t<string>('mediumLinks.howToBuy')} ></HowTo>
                        </div>
                    </div>

                    </div>}
                    {progress == Progress.Transaction && <div className={styles.transactionWrapper}>
                        <div  className={styles.detailsWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img className={styles.userImage}/>
                            </div>
                            <hr className={styles.horizontalLine}/>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.transaction.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="draw3ItemsPopup.transaction.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionDone && <div className={styles.transactionWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img className={styles.userImage}/>
                            </div>
                            <hr className={styles.horizontalLine}/>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.transactionCompleted.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="draw3ItemsPopup.transactionCompleted.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.transactionFailed.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="draw3ItemsPopup.transactionFailed.description"></Trans>
                            </div>
                            <div className={styles.error}>
                                <div className={styles.errorMsg}><Trans i18nKey="draw3ItemsPopup.transactionFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomeWrapper}>
                                    <GenericButton hollow={true} value={t<string>('draw3ItemsPopup.transactionFailed.gotoHome')} onClick={()=>{gotoHome() }} />
                                    {/* <input className={styles.gotoHome} type="button"  value={t<string>('draw3ItemsPopup.transactionFailed.gotoHome')} onClick={() => {gotoHome()}}/> */}
                                </div>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton value={t<string>('draw3ItemsPopup.transactionFailed.tryAgain')} onClick={()=>{tryAgain() }} />
                                    {/* <input className={styles.tryAgain} type="button"  value={t<string>('draw3ItemsPopup.transactionFailed.tryAgain')} onClick={ () => {tryAgain()}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDraw && <div className={styles.luckyDrawWrapper}>
                        <video className={styles.openBox} src={openBoxMp4} autoPlay={true}/>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDraw.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDraw.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <div className={styles.loadingDot}></div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawDone && <div className={styles.luckyDrawDoneWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawDone.title"></Trans>
                            </div>
                            <div className={styles.description}>
                            <Trans i18nKey="draw3ItemsPopup.luckyDrawDone.description.part1"></Trans>
                            {getFinalItemsDisplay()}
                            <div className={styles.message}><Trans i18nKey="draw3ItemsPopup.luckyDrawDone.description.part2"></Trans></div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomePageWrapper}>
                                    <GenericButton hollow={true} value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoHome')} onClick={()=>{gotoHome() }} />
                                    {/* <input className={styles.gotoHomePageButton} type="button"  value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoHome')} onClick={() => {gotoHome()}}/> */}
                                </div>
                                <div className={styles.collectionWrapper}>
                                    <GenericButton value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoCollection')} onClick={()=>{gotoCollection() }} />
                                    {/* <input className={styles.collectionButton} type="button"  value={t<string>('draw3ItemsPopup.luckyDrawDone.gotoCollection')} onClick={ () => {gotoCollection()}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.description"></Trans>
                            </div>
                            <div className={styles.error}>
                                <div className={styles.errorMsg}><Trans i18nKey="draw3ItemsPopup.luckyDrawFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton  value={t<string>('draw3ItemsPopup.luckyDrawFailed.tryAgain')} onClick={()=>{ }} />
                                    {/* <input className={styles.tryAgain} type="button" value={t<string>('draw3ItemsPopup.luckyDrawFailed.tryAgain')} onClick={ () => { }}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}


export default Draw3ItemsPopup
