import companyLogo from '../images/logo/company_logo.png'
import instagramLogo from '../images/logo/instagram.png'
import facebookLogo from '../images/logo/facebook.png'
import twitterLogo from '../images/logo/twitter_logo.png'
import telegramLogo from '../images/logo/telegram_logo.png'
import discordLogo from '../images/logo/discord_logo.png'
import mediumLogo from '../images/logo/medium_logo.png'
import styles from './Footer.module.scss'
import { Trans } from 'react-i18next'

function Footer() {

    return (
        <div className={styles.wrapper}>
            <div className={styles.logoSection}>
                <img className={styles.companyLogo} src={companyLogo}/>
            </div>
            <div className={styles.socialMediaSection}>
                <a className={styles.logoWrapper} href="https://medium.com/hk-1-football-nft" target="_blank"><img className={styles.logo} src={mediumLogo}/></a>
                <a className={styles.logoWrapper} href="https://t.me/hkfootballnft" target="_blank"><img className={styles.logo} src={telegramLogo}/></a>
                <a className={styles.logoWrapper} href="https://www.facebook.com/hkfootballnft/" target="_blank"><img className={styles.logo} src={facebookLogo}/></a>
                <a className={styles.logoWrapper} href="https://twitter.com/hkfootballnft" target="_blank"><img className={styles.logo} src={twitterLogo}/></a>
                     </div>
            <div className={styles.policySection}>
                <a className={styles.policy} href="./privacy/" target="_blank"><Trans i18nKey='footer.privacy_policy'></Trans></a>
                <a href="mailto:contact@olivex.ai" className={styles.policy}><Trans i18nKey='footer.contact_us'></Trans></a>
            </div>
            <div className={styles.copyRightSection}>
                <Trans i18nKey='footer.copy_right'></Trans>
            </div>
        </div>
    )
}

export default Footer