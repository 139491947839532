import React from 'react'
import styles from './MessagePopup.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import GenericButton from '../../common/genericButton/GenericButton'
import { useMessagePopupContext } from '../../contexts/popups/MessagePopupContextProvider'

function MessagePopup() {
    const { t, i18n } = useTranslation()
    const messagePopupContext = useMessagePopupContext()

    const onClose = () => {
        messagePopupContext?.show(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.popupWrapper}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.title}>
                        {messagePopupContext?.title}
                    </div>
                    <div className={styles.description}>
                        {messagePopupContext?.description}
                    </div>
                    <div className={styles.ctaSection}>
                        <GenericButton value={t<string>('messagePopup.ctaButton')} onClick={()=>{ onClose() }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessagePopup