import React from 'react'
import styles from './termsAndConditions.module.scss'
import { Trans } from 'react-i18next'

function onClick (){
    console.log("Display: termsAndConditions")
}

function TermsAndConditions ({text} : {text: string}) {
    return ( <div className={styles.wrapper} onClick={() => onClick()}>
                <div className={styles.detail} >{text} </div> 
                <div className={styles.terms}>&nbsp;{<Trans i18nKey="termsAndConditions"></Trans>}</div> 
            </div>
    )
}

export default TermsAndConditions