import React, { useEffect } from 'react'
import styles from './LoadingPopup.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import Loading from '../../common/loading/Loading'

function LoadingPopup() {
    const { t, i18n } = useTranslation()

    useEffect(() => {
        console.log("Loading")
        return () => {

        }
    }, [])

    return (
        <div className={styles.bgWrapper}>
            <Loading width={256} height={256}/>
        </div>
    )
}

export default LoadingPopup
