import React, { useEffect } from 'react'
import styles from './DrawItemDetailsPage.module.scss'

import { Trans, useTranslation } from 'react-i18next'

import TitleBar from '../../titleBar/TitleBar'
import Price from '../../common/price/Price'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import { usePopupUpdateContext } from '../../contexts/PopupContextProvider'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { useDraw3ItemsPopupContext } from '../../contexts/popups/Draw3ItemsPopupContextProvider'
import { useProbabilityPopupContext } from '../../contexts/popups/ProbabilityPopupContextProvider'
import alertCircle from "../../images/alert-circle.png"
import collectionBoxImg from "../../images/rcfc_2021_std_p3_s1_480 3.png"
import player1Img from "../../images/pack1/PlayerThumbnail_RCFC_1.png"
import player4Img from "../../images/pack1/PlayerThumbnail_RCFC_4.png"
import player10Img from "../../images/pack1/PlayerThumbnail_RCFC_10.png"
import player14Img from "../../images/pack1/PlayerThumbnail_RCFC_14.png"
import player23Img from "../../images/pack1/PlayerThumbnail_RCFC_23.png"
import player27Img from "../../images/pack1/PlayerThumbnail_RCFC_27.png"
import player22Img from "../../images/pack1/PlayerThumbnail_RCFC_22.png"
import { isMobile, MobileView } from 'react-device-detect'
import GenericButton from '../../common/genericButton/GenericButton'
import HowTo from '../../common/howTo/HowTo'
import {singlePrice, packPrice} from '../../AppSettings.json'

function DrawItemDetailsPage() {
    const { t, i18n } = useTranslation()
    const appContext = useAppContext()
    const popupUpdateContext = usePopupUpdateContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const draw3ItemsPopupContext = useDraw3ItemsPopupContext()
    const probabilityPopupContext = useProbabilityPopupContext()

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {

        }
    }, [])

    const draw1Card = () => {
        if (appContext?.accounts) {
            popupUpdateContext?.showDrawItemPopup(true)
        } else {
            connectWalletPopupContext?.show(true)
        }
    }

    const draw3Cards = () => {
        if (appContext?.accounts) {
            draw3ItemsPopupContext?.show(true)
        } else {
            connectWalletPopupContext?.show(true)
        }
    }

    const onProbabilityClicked = (drawType : string = "single") => {
        probabilityPopupContext?.show(true , drawType);
    }
    

    return (
        <div className={styles.bgWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView></MobileAlertView>
            </MobileView>
            {!isMobile && <div className={styles.wrapper}>
                <div className={styles.imageWrapper}>
                    <img className={styles.collectionImage} src={collectionBoxImg}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div>
                        <div className={styles.clubTitle}><Trans i18nKey="drawItemDetailsPage.clubTitle"></Trans></div>
                        <div className={styles.itemTitle}><Trans i18nKey="drawItemDetailsPage.itemTitle"></Trans></div>
                    </div>
                    <div>
                        <div className={styles.description}>
                            <Trans i18nKey="drawItemDetailsPage.description"></Trans>
                        </div>
                    </div>
                    <div className={styles.memberSection}>
                        <div className={styles.memberTitle}>
                        <Trans i18nKey='drawItemSection.membersTitle'></Trans>
                        </div>
                        <div className={styles.memberIconSection}>
                            <div className={styles.memberIconRow}>
                                <div className={styles.memberIcon}><img src={player1Img} /></div>
                                <div className={styles.memberIcon}><img src={player4Img} /></div>
                                <div className={styles.memberIcon}><img src={player10Img} /></div>
                                <div className={styles.memberIcon}><img src={player14Img} /></div>
                                <div className={styles.memberIcon}><img src={player23Img} /></div>
                                <div className={styles.memberIcon}><img src={player27Img} /></div>
                                <div className={styles.memberIcon}><img src={player22Img} /></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.pricingTitle}>
                        <Trans i18nKey="drawItemDetailsPage.pricingTitle"></Trans>
                    </div>
                    <div className={styles.drawSection}>
                        <div className={styles.drawCard}>
                            <div className={styles.title}>
                            <div className={styles.drawPart1}><Trans i18nKey="drawItemDetailsPage.draw1Card.title.part1"></Trans></div>
                                <div className={styles.drawNumber}><Trans i18nKey="drawItemDetailsPage.draw1Card.title.part2"></Trans></div>
                                <div className={styles.drawPart3}><Trans i18nKey="drawItemDetailsPage.draw1Card.title.part3"></Trans></div>
                            </div>
                            <Price eth={singlePrice}/>
                            <GenericButton value={t<string>('drawItemDetailsPage.draw1Card.ctaButton.value')} onClick={()=>{ draw1Card() }} />
                            <div className={styles.probability} onClick={()=>{onProbabilityClicked("single")}} > 
                            <   img className={styles.probabilityIcon} src={alertCircle} />
                                <div className={styles.title}> {t<string>('drawItemDetailsPage.draw1Card.probability')} </div>
                            </div>
                     </div>
                        <div className={styles.drawCard}>
                            <div className={styles.title}>
                                <div className={styles.drawPart1}><Trans i18nKey="drawItemDetailsPage.draw3Cards.title.part1"></Trans></div>
                                <div className={styles.drawNumber}><Trans i18nKey="drawItemDetailsPage.draw3Cards.title.part2"></Trans></div>
                                <div className={styles.drawPart3}><Trans i18nKey="drawItemDetailsPage.draw3Cards.title.part3"></Trans></div>
                            </div>
                            <Price eth={packPrice}/>
                            <GenericButton value={t<string>('drawItemDetailsPage.draw3Cards.ctaButton.value')} onClick={()=>{ draw3Cards() }} />
                            <div className={styles.probability} onClick={()=>{onProbabilityClicked("pack")}} > 
                                <img className={styles.probabilityIcon} src={alertCircle} />
                                <div className={styles.title}> {t<string>('drawItemDetailsPage.draw1Card.probability')} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default DrawItemDetailsPage