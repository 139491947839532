import React, { useContext, useEffect, useState } from 'react'

export type AppContent = {
    walletType: string | undefined,
    accounts: string[] | undefined,
    ethPrice: number | undefined,
    bidPrice: number | undefined
}

export type AppUpdateContent = {
    setAppUpdateContent: (walletType: string | undefined, accounts: string[] | undefined) => void
    setAccounts: (accounts: string[] | undefined) => void
    setEthPrice: (ethPrice: number | undefined) => void
    setBidPrice: (bidPrice: number | undefined) => void
}

const AppContext = React.createContext<AppContent | undefined>(undefined)
const AppUpdateContext = React.createContext<AppUpdateContent | undefined>(undefined)

export function useAppContext() {
    return useContext(AppContext)
}

export function useAppUpdateContext() {
    return useContext(AppUpdateContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function AppContextProvider(props: Props) {

    const [appContent, setAppContent] = useState<AppContent>({
        walletType: undefined,
        accounts: undefined,
        ethPrice: undefined,
        bidPrice: undefined,
    })

    const setAppUpdateContent = (walletType: string | undefined, newAccounts: string[] | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'walletType': walletType, 'accounts': newAccounts}) );
        sessionStorage.setItem("walletType", walletType? walletType : "");
        sessionStorage.setItem("accounts", newAccounts? newAccounts[0] : "");
    }

    const setAccounts = (newAccounts: string[] | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'accounts': newAccounts}) );
        sessionStorage.setItem("accounts", newAccounts? newAccounts[0] : "");
    }

    const setEthPrice = (ethPrice: number | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'ethPrice': ethPrice})  );
    }

    const setBidPrice = (bidPrice: number | undefined) => {
        setAppContent( (prevState)=>({ ...prevState ,  'bidPrice': bidPrice})  );
    }

    const appUpdateContent: AppUpdateContent = {
        setAppUpdateContent: (walletType: string | undefined, accounts: string[] | undefined) => { setAppUpdateContent(walletType, accounts) },
        setAccounts: (accounts: string[] | undefined) => { setAccounts(accounts) },
        setEthPrice: (ethPrice: number | undefined) => { setEthPrice(ethPrice) },
        setBidPrice: (bidPrice: number | undefined) => { setBidPrice(bidPrice) }
    }

    return (
        <AppContext.Provider value={appContent}>
            <AppUpdateContext.Provider value={appUpdateContent}>
                {props.children}
            </AppUpdateContext.Provider>
        </AppContext.Provider>
    )
}

export default AppContextProvider
