import Portis from '@portis/web3'
import React, { useContext, useState } from 'react'

export type PortisContent = {
    portis: Portis | undefined,
    setPortis: (portis: any) => void
}

const PortisContext = React.createContext<PortisContent | undefined>(undefined)

export function usePortisContext() {
    return useContext(PortisContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function PortisContextProvider(props: Props) {
    const [portisContent, setPortisContent] = useState<PortisContent>({
        portis: undefined,
        setPortis: (portis: any) => {
            setPortisContent(
                {...portisContent, portis: portis}
            )
        }
    })

    return (
        <PortisContext.Provider value={portisContent}>
            {props.children}
        </PortisContext.Provider>
    )
}

export default PortisContextProvider
