import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import styles from './DateCounter.module.scss'

export function IsExpired ({year, month, day, hour, minute, second} : {year: number, month: number, day: number, hour: number, minute: number, second: number}) {
    var dueUTCDate = Date.UTC(year, month, day, hour, minute, second)
    var nowDate = new Date()
    var nowUTCDate = Date.UTC(nowDate.getUTCFullYear(), nowDate.getUTCMonth(), nowDate.getUTCDate(), nowDate.getUTCHours(), nowDate.getUTCMinutes(), nowDate.getUTCSeconds())    
    var diff = (dueUTCDate - nowUTCDate)
    return (diff < 0 )
}

function DateCounter({year, month, day, hour, minute, second} : {year: number, month: number, day: number, hour: number, minute: number, second: number}) {
    type RemainingTime = {
        day: string,
        hour: string,
        minute: string,
        second: string
    }

    const [remainingTime, setRemainingTime] = useState<RemainingTime | undefined>(undefined)
    useEffect(() => {
        setInterval(() => {
            var dueUTCDate = Date.UTC(year, month, day, hour, minute, second)
            var nowDate = new Date()
            var nowUTCDate = Date.UTC(nowDate.getUTCFullYear(), nowDate.getUTCMonth(), nowDate.getUTCDate(), nowDate.getUTCHours(), nowDate.getUTCMinutes(), nowDate.getUTCSeconds())
            var diff = (dueUTCDate - nowUTCDate) / 1000
            var minutes = diff / 60
            var remainingSeconds = diff % 60
            var hours = minutes / 60
            var remainingMinutes = Math.floor(minutes % 60)
            var remainingDays = Math.floor(hours / 24)
            var remainingHours = Math.floor(hours % 24)
            var formattedRemainingDays = remainingDays.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })
            var formattedRemainingHours = remainingHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })
            var formattedRemainingMinutes = remainingMinutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })
            var formattedRemainingSeconds = remainingSeconds.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })

            setRemainingTime({
                day: formattedRemainingDays,
                hour: formattedRemainingHours,
                minute: formattedRemainingMinutes,
                second: formattedRemainingSeconds
            })
        }, 1000)
        return () => {
        }
    }, [])

    return (
        <div>
            <div className={styles.dateWrapper}>
                <div className={`${styles.dayWrapper} ${styles.dateSection}`}>
                    <div className={`${styles.dayValueWrapper} ${styles.dateValue}`}>{remainingTime? remainingTime.day : "00"}</div>
                    <div className={`${styles.dayTitleWrapper} ${styles.dateTitle}`}>
                        <Trans i18nKey="DateCounter.day"></Trans>
                    </div>
                </div>
                <div className={`${styles.hourWrapper} ${styles.dateSection}`}>
                    <div className={`${styles.hourValueWrapper} ${styles.dateValue}`}>{remainingTime? remainingTime.hour : "00"}</div>
                    <div className={`${styles.hourTitleWrapper} ${styles.dateTitle}`}>
                        <Trans i18nKey="DateCounter.hour"></Trans>
                    </div>
                </div>
                <div className={styles.colonWrapper}>:</div>
                <div className={`${styles.minWrapper} ${styles.dateSection}`}>
                    <div className={`${styles.minValueWrapper} ${styles.dateValue}`}>{remainingTime? remainingTime.minute : "00"}</div>
                    <div className={`${styles.minTitleWrapper} ${styles.dateTitle}`}>
                        <Trans i18nKey="DateCounter.minute"></Trans>
                    </div>
                </div>
                <div className={styles.colonWrapper}>:</div>
                <div className={`${styles.secWrapper} ${styles.dateSection}`}>
                    <div className={`${styles.secValueWrapper} ${styles.dateValue}`}>{remainingTime? remainingTime.second : "00"}</div>
                    <div className={`${styles.secTitleWrapper} ${styles.dateTitle}`}>
                        <Trans i18nKey="DateCounter.second"></Trans>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateCounter
