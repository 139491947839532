import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useContext, useState } from 'react'
import ConnectWalletMessagePopupContextProvider from './popups/ConnectWalletMessagePopupContextProvider'
import ConnectWalletPopupContextProvider from './popups/ConnectWalletPopupContextProvider'
import Draw3ItemsPopupContextProvider from './popups/Draw3ItemsPopupContextProvider'
import LoadingPopupContextProvider from './popups/LoadingPopupContextProvider'
import MessagePopupContextProvider from './popups/MessagePopupContextProvider'
import PlaceBidPopupContextProvider from './popups/PlaceBidPopupContextProvider'
import WrapEthPopupContextProvider from './popups/WrapEthPopupContextProvider'
import ProbabilityPopupContextProvider from './popups/ProbabilityPopupContextProvider'

export type PopupContent = {
    shouldShowSubscriptionPopup: boolean,
    shouldShowSubscriptionConfirmPopup: boolean,
    shouldShowDrawItemPopup: boolean,
    shouldShowLoadingPopup: boolean,
    shouldShowConnectWalletPopup: boolean,
    shouldShowProbabilityPopup: boolean
}

export type PopupUpdateContent = {
    showSubscriptionPopup: (popupContent: boolean) => void,
    showSubscriptionConfirmPopup: (popupContent: boolean) => void,
    showDrawItemPopup: (popupContent: boolean) => void,
    showLoadingPopup: (popupContent: boolean) => void,
    showConnectWalletPopup: (showPopup: boolean) => void,
    showProbabilityPopup: (showPopup: boolean) => void
}

const PopupContext = React.createContext<PopupContent | undefined>(undefined)
const PopupUpdateContext = React.createContext<PopupUpdateContent | undefined>(undefined)

export function usePopupContext() {
    return useContext(PopupContext)
}

export function usePopupUpdateContext() {
    return useContext(PopupUpdateContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function PopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<PopupContent>({
        shouldShowSubscriptionPopup: false,
        shouldShowSubscriptionConfirmPopup: false,
        shouldShowDrawItemPopup: false,
        shouldShowLoadingPopup: false,
        shouldShowConnectWalletPopup: false,
        shouldShowProbabilityPopup: false
    })

    const showSubscriptionPopup = (shouldShowSubscriptionPopup: boolean) => {
        setPopupContent({...popupContent, shouldShowSubscriptionPopup: shouldShowSubscriptionPopup} as PopupContent)
    }

    const showSubscriptionConfirmPopup = (shouldShowSubscriptionConfirmPopup: boolean) => {
        setPopupContent({...popupContent, shouldShowSubscriptionConfirmPopup: shouldShowSubscriptionConfirmPopup} as PopupContent)
    }

    const showDrawItemPopup = (shouldShowDrawItemPopup: boolean) => {
        if (shouldShowDrawItemPopup) {
            const targetElement = document.querySelector('body');
            if (targetElement) {
                disableBodyScroll(targetElement);
            }
        } else {
            const targetElement = document.querySelector('body');
            if (targetElement) {
                enableBodyScroll(targetElement);
            }
        }
        setPopupContent({...popupContent, shouldShowDrawItemPopup: shouldShowDrawItemPopup} as PopupContent)
    }

    const showLoadingPopup = (shouldShowLoadingPopup: boolean) => {
        console.log('shouldShowLoadingPopup ' + JSON.stringify(popupContent))
        setPopupContent({...popupContent, shouldShowLoadingPopup: shouldShowLoadingPopup} as PopupContent)
    }

    const showConnectWalletPopup = (shouldShowPopup: boolean) => {
        console.log('shouldShowConnectWallet ' + shouldShowPopup)
        setPopupContent({...popupContent, shouldShowConnectWalletPopup: shouldShowPopup} as PopupContent)
    }

    const showProbabilityPopup = (shouldShowPopup: boolean) => {
        console.log('shouldShowProbabilityPopup ' + shouldShowPopup)
        setPopupContent({...popupContent, shouldShowProbabilityPopup: shouldShowPopup} as PopupContent)
    }

    const [popupUpdateContent, setPopupUpdateContent] = useState<PopupUpdateContent>({
        showSubscriptionPopup: showSubscriptionPopup,
        showSubscriptionConfirmPopup: showSubscriptionConfirmPopup,
        showDrawItemPopup: showDrawItemPopup,
        showLoadingPopup: showLoadingPopup,
        showConnectWalletPopup: showConnectWalletPopup,
        showProbabilityPopup: showProbabilityPopup,
    })

    return (
        <PopupContext.Provider value={popupContent}>
            <PopupUpdateContext.Provider value={popupUpdateContent}>
                <ProbabilityPopupContextProvider>
                    <Draw3ItemsPopupContextProvider>
                        <ConnectWalletPopupContextProvider>
                            <ConnectWalletMessagePopupContextProvider>
                                <WrapEthPopupContextProvider>
                                    <PlaceBidPopupContextProvider>
                                        <LoadingPopupContextProvider>
                                            <MessagePopupContextProvider>
                                                {props.children}
                                            </MessagePopupContextProvider>
                                        </LoadingPopupContextProvider>
                                    </PlaceBidPopupContextProvider>
                                </WrapEthPopupContextProvider>
                            </ConnectWalletMessagePopupContextProvider>
                        </ConnectWalletPopupContextProvider>
                    </Draw3ItemsPopupContextProvider>
                </ProbabilityPopupContextProvider>
            </PopupUpdateContext.Provider>
        </PopupContext.Provider>
    )
}

export default PopupContextProvider
