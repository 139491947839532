import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './ConnectWalletPopup.module.scss'
import metaMaskLogo from '../../images/logo/Logo_MetaMask_256h.png'
import portisLogo from '../../images/logo/Logo_Portis_256h.png'
import howIcon from './images/how_icon.png'
import howIconWhite from './images/how_icon_white.png'
import { useAppUpdateContext } from '../../contexts/AppContextProvider'
import { usePopupUpdateContext } from '../../contexts/PopupContextProvider'
import detectEthereumProvider from '@metamask/detect-provider'
import { Network, OpenSeaPort } from 'opensea-js'
import Portis from '@portis/web3';
import Web3 from 'web3';
import { useOpenSeaContext, useOpenSeaUpdateContext } from '../../contexts/OpenSeaContextProvider'
import { useConnectWalletMessagePopupContext } from '../../contexts/popups/ConnectWalletMessagePopupContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { usePortisContext } from '../../contexts/wallets/PortisContextProvider'
import { useWeb3Context } from '../../contexts/Web3ContextProvider'
import { useHistory } from "react-router-dom";
import loadingIcon from './images/loading.png';
import GenericButton from '../../common/genericButton/GenericButton'
import HowTo from '../../common/howTo/HowTo'
import {env, portisAppid, openseaAppid, alchemyRinkebyApi, alchemyMainnetApi } from '../../secrets.json'

function ConnectWalletPopup() {
    const { t, i18n } = useTranslation()
    const appUpdateContext = useAppUpdateContext()
    const popupUpdateContext = usePopupUpdateContext()
    const openSeaContext = useOpenSeaContext()
    const openSeaUpdateContext = useOpenSeaUpdateContext()
    const connectWalletMessagePopupContext = useConnectWalletMessagePopupContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const [metaMaskLoading, setMetaMaskLoading] = useState(false)
    const [portisLoading, setPortisLoading] = useState(false)
    const portisContext = usePortisContext()
    const web3Context = useWeb3Context()
    const history = useHistory()
    const isEnvMainnet = (env == "mainnet")

    const connectMetaMask = async () => {
        console.log("connect MetaMask")

        //const provider = (window as any).ethereum
        try {
            setMetaMaskLoading(true)

            const provider: any = await detectEthereumProvider({
                mustBeMetaMask: true
            })

            if (provider) {

                // If the provider returned by detectEthereumProvider is not the same as
                // window.ethereum, something is overwriting it, perhaps another wallet.
                if (provider !== window.ethereum) {
                    console.error('Do you have multiple wallets installed?');
                    return
                }

                // Access the decentralized web!
                var accounts = await provider.request({ method: 'eth_requestAccounts'})
                appUpdateContext?.setAppUpdateContent("MetaMask", accounts)

                if (provider) {
                    var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                        networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                        // apiKey: opensea_appid
                    })
                    openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)
                }

                sessionStorage.setItem("wallet_type", "MetaMask")
                connectWalletPopupContext?.show(false)
                window.scrollTo(0, 0)

            } else {
                console.log("No Provider")
                window.open("https://metamask.io/download.html");

            }
        } catch (e) {
            console.log('Error ' + e)
        } finally {
            setMetaMaskLoading(false)
        }
    }

    const connectPortis = async () => {
        console.log("connect Portis")
        setPortisLoading(true)
        
        const portis = new Portis(portisAppid, isEnvMainnet? 'mainnet' : 'rinkeby')
        const provider = portis.provider
        const web3 = new Web3(provider)

        web3.eth.getAccounts((error, accounts) => {
            if (error) {
                console.log('error ' + JSON.stringify(error))
                setPortisLoading(false)
                return
            }

            console.log("Portis " + JSON.stringify(accounts))
            appUpdateContext?.setAppUpdateContent("Portis", accounts)
            portisContext?.setPortis(portis)
            web3Context?.setWeb3(web3)
            sessionStorage.setItem("wallet_type", "Portis")

            var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                // apiKey: opensea_appid
            })
            openSeaUpdateContext?.setOpenSeaContent(newOpenSeaPort)

            setPortisLoading(false)
            connectWalletPopupContext?.show(false)
            window.scrollTo(0, 0)
        })
    }

    const onClose = () => {
        connectWalletPopupContext?.show(false)
    }

    const showMessagePopup = () => {
        console.log('showshow')
        connectWalletMessagePopupContext?.show(true)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.parentWrapper}>
                <input type="button" className={styles.closeButton} value={t<string>('homepage.subscriptionPopup.close')} onClick={()=> { onClose() }}/>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}><Trans i18nKey='connectWalletPopup.title'></Trans></div>
                    </div>
                    <div className={styles.walletWrapper}>
                        <div className={styles.walletSection}>
                            <div className={styles.logoWrapper}><img className={styles.metaMaskLogo} src={metaMaskLogo}/></div>
                            <div className={styles.description}><Trans i18nKey='wallet.metamask.description'></Trans></div>
                            {metaMaskLoading?<div className={styles.loadingIconWrapper}> <img width="128" height="128" className={styles.loadingIcon} src={loadingIcon} /> </div> :
                            <div>
                                <div>
                                    <div className={styles.ctaButtonWrapper}><GenericButton value={t<string>('wallet.signIn')} onClick={()=>{ connectMetaMask() }}/></div>
                                </div>
                                <div className={styles.createWallet}>
                                    <img className={styles.howIcon} src={howIcon} onClick={() => { showMessagePopup() }}/>
                                    <div className={styles.createWalletLink}>
                                        <Trans i18nKey='wallet.createMetaMask'></Trans>
                                        <input type="button" className={styles.createButton} value={t<string>('wallet.create')} onClick={()=>connectMetaMask()}/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className={styles.walletSection}>
                            <div className={styles.logoWrapper}><img className={styles.portisLogo} src={portisLogo}/></div>
                            <div className={styles.description}><Trans i18nKey='wallet.portis.description'></Trans></div>
                            {portisLoading?<div className={styles.loadingIconWrapper}> <img width="128" height="128" className={styles.loadingIcon} src={loadingIcon} /> </div> :
                            <div>
                                <div>
                                    <div className={styles.ctaButtonWrapper}><GenericButton value={t<string>('wallet.signIn')} onClick={()=>{ connectPortis() }}/></div>
                                </div>
                                <div className={styles.createWallet}>
                                    <img className={styles.howIcon} src={howIcon} onClick={() => { showMessagePopup() }}/>
                                    <div className={styles.createWalletLink}>
                                        <Trans i18nKey='wallet.createPortis'></Trans>
                                        <input type="button" className={styles.createButton} value={t<string>('wallet.create')} onClick={()=>connectPortis()}/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className={styles.howToConnectWrapper}><HowTo text={t<string>('connectWalletPopup.howToConnect')} link={t<string>('mediumLinks.setUpWallet')}/></div>
                </div>
            </div>
        </div>
    )
}

export default ConnectWalletPopup
