import styles from './BidItemDetailsPage.module.scss'
import { OpenSeaAsset, Order, WyvernSchemaName } from 'opensea-js/lib/types'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BidItemImage from '../../common/bidItemImage/BidItemImage'
import DateCounter from '../../common/dateCounter/DateCounter'
import {IsExpired} from '../../common/dateCounter/DateCounter'
import GenericButton from '../../common/genericButton/GenericButton'
import Loading from '../../common/loading/Loading'
import Price from '../../common/price/Price'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { usePlaceBidPopupContext } from '../../contexts/popups/PlaceBidPopupContextProvider'
import TitleBar from '../../titleBar/TitleBar'
import lcMp4 from '../../images/RCFC_2021_BEST_1_LS.mp4'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import HowTo from '../../common/howTo/HowTo'
import {bid_start_month, bid_start_day, bid_start_hour, bid_start_minute } from '../../AppSettings.json'
import {bid_end_month, bid_end_day, bid_end_hour, bid_end_minute } from '../../AppSettings.json'
import {env, assetAddressMainnet, assetAddressRinkeby, auctionIdMainnet, auctionIdRinkeby } from '../../secrets.json'

function BidItemDetailsPage() {
    const openSeaContext = useOpenSeaContext()
    const placeBidPopupContext = usePlaceBidPopupContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const [bidItem, setBidItem] = useState<OpenSeaAsset | undefined>(undefined)
    const appContext = useAppContext()
    const [ isEnded , setIsEnded ] = useState<boolean>(true)
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {
        setInterval(() => { 
            let isExpired = IsExpired( {year:2021,  month:bid_end_month-1, day:bid_end_day , hour:bid_end_hour,  minute:bid_end_minute , second:0} );
            setIsEnded(isExpired);  
        } , 1000 ) 
    } , [] )

    useEffect(() => {
        var tokenAddress = isEnvMainnet? assetAddressMainnet : assetAddressRinkeby;
        var tokenId = isEnvMainnet? auctionIdMainnet : auctionIdRinkeby;

        async function getAsset() {
            try {
                var asset = await openSeaContext?.api.getAsset({
                    "tokenAddress": tokenAddress,
                    "tokenId": tokenId
                })

                if (asset) {
                    setBidItem(asset)
                }
            } catch (e) {
                console.log("BidItemDetails Error " + JSON.stringify(e, null, 2))
            }
        }

        getAsset()
        return () => {

        }

    }, [])

    const getAccountAddress = (address: string | undefined) => {
        if (address) {
            var abbrAddress = address.substr(0,6) + "..." + address.substr(address.length - 4)
            return abbrAddress
        }
        return ""
    }

    const getCreatedTime = (o : Order) => {
        var ct = o.createdTime
        if (ct) {
            var date = new Date(ct.toNumber() * 1000)
            return date.toDateString()
        }
        return ""
    }

    const onClick = async () => {
        if (appContext?.accounts) {
            placeBidPopupContext?.show(true)
            //wrapEthPopupContext?.show(true)
        } else {
            connectWalletPopupContext?.show(true)
        }
    }

    const { t, i18n } = useTranslation()
    return (
        <div className={styles.bgWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView/>
            </MobileView>
            {!isMobile && <div className={styles.contentWrapper}>
                <div className={styles.informationSection}>
                    <div className={styles.videoWrapper}>
                        <video className={styles.videoContent} src={lcMp4} autoPlay={true} width={480} height={480} loop={true} muted/>
                    </div>
                    <div className={styles.informationWrapper}>
                        <div className={styles.logoWrapper}>
                        <BidItemImage/>
                        </div>
                        <div className={styles.descriptionWrapper}>
                            <Trans i18nKey='bidItemDetailsPage.description'></Trans>
                        </div>
                        <div className={styles.titleWrapper}>
                            <div className={styles.history}>
                                <Trans i18nKey='bidItemDetailsPage.history'></Trans>
                                <hr className={styles.line}/>
                            </div>
                            <HowTo text={t<string>('bidItemDetailsPage.howToBid')} link={t<string>('mediumLinks.howToBuy')}></HowTo>
                        </div>
                        <div className={styles.currentBidsWrapper}>
                            
                            {bidItem? bidItem.buyOrders?.map(buyOrder => {
                                // console.log(JSON.stringify(buyOrder,null,2))
                                // console.log("Buy Order", buyOrder)
                                // TODO : UI Format
                                return buyOrder && <div className={styles.buyOrder}>
                                    <div className={styles.bidTaker}>{getAccountAddress(buyOrder?.makerAccount?.address)}</div>
                                    <div className={styles.bidPrice}><Trans i18nKey="bidItemDetailsPage.buyOrder.bid"></Trans>{buyOrder?.basePrice.shift(-18).toString()} WETH</div>
                                    <div className={styles.bidTime}>{getCreatedTime(buyOrder)}</div>
                                </div>
                            }) : <Loading width={96} height={96}/> }
                        </div>
                    </div>
                </div>
                <div className={styles.timePriceSection}>
                    <div className={styles.endAfter}>
                        <Trans i18nKey="bidItemDetailsPage.endAfter"></Trans>
                    </div> 
                        { !isEnded  && <div className={styles.countdown}>
                            <DateCounter year={2021} month={bid_end_month-1} day={bid_end_day} hour={bid_end_hour} minute={bid_end_minute} second={0}/>
                        </div> }
                    <div className={styles.ctaWrapper}>
                        <GenericButton enabled={!isEnded} onClick={()=>{onClick()}} value={t<string>('bidItemDetailsPage.ctaButton')}/>
                    </div>
                    <div className={styles.priceWrapper}>
                        {appContext != null && <Price eth={appContext?.bidPrice}/>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default BidItemDetailsPage
