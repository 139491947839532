import React from 'react'
import loadingIcon from './images/loading.png'
import styles from './Loading.module.scss'

function Loading({ width, height} : { width : number, height: number}) {
    return (
        <div className={styles.wrapper}>
            <img className={styles.loadingIcon} src={loadingIcon} width={width} height={height}/>
        </div>
    )
}

export default Loading
