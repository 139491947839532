import { OpenSeaAsset, OpenSeaAssetQuery } from 'opensea-js/lib/types'
import { useEffect, useState } from 'react'
import { isMobile, MobileView } from 'react-device-detect'
import { Trans } from 'react-i18next'
import Loading from '../../common/loading/Loading'
import MobileAlertView from '../../common/mobileAlertView/MobileAlertView'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import TitleBar from '../../titleBar/TitleBar'
import styles from "./CollectionPage.module.scss"
import {env, assetAddressRinkeby, assetAddressMainnet } from '../../secrets.json'

function CollectionPage() {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<OpenSeaAsset[] | undefined>(undefined)
    const openSea = useOpenSeaContext()
    const appContext = useAppContext()
    const isEnvMainnet = (env == "mainnet")

    useEffect(() => {
        window.scrollTo(0, 0)

        async function getAssets() {
            if (appContext?.accounts) {
                var query : OpenSeaAssetQuery = {
                    owner: appContext.accounts[0],
                    asset_contract_address: isEnvMainnet? assetAddressMainnet:assetAddressRinkeby
                }
                setLoading(true)
                var result = await openSea?.api.getAssets(query)
                // console.log(JSON.stringify(result, null, 2))
                setItems(result?.assets)
                setLoading(false)
            } else {

            }
        }
        getAssets()
        return () => {

        }
    }, [appContext?.accounts, appContext?.walletType])
    return (
        <div className={styles.parentWrapper}>
            <TitleBar/>
            <MobileView>
                <MobileAlertView></MobileAlertView>
            </MobileView>
            {!isMobile &&
            <div className={styles.contentWrapper}>
                <div className={styles.title}>
                    <Trans i18nKey="collectionPage.title"></Trans>
                </div>
                <div className={styles.collectionWrapper}>
                    {loading && <div className={styles.loadingWrapper}><Loading width={128} height={128}/></div> }
                    {(!loading && items && items.length > 0 ) &&  <div className={styles.itemList}>
                        {items?.map((item, key) => {
                            return <div className={styles.itemWrapper} key={key}>
                                    <div><img src={item.imagePreviewUrl}/></div>
                                    <div className={styles.descriptionWrapper}>
                                        <div className={styles.name}>{item.name}</div>
                                    </div>
                                </div>
                            })}
                    </div>}
                    {(!loading && (!items || items.length == 0 )) && <div className={styles.noItemMsgWrapper}>
                        <div className={styles.noItemMsg}><Trans i18nKey="collectionPage.noItemMsg"></Trans></div>
                    </div>}
                </div>
            </div>}
        </div>
    )
}

export default CollectionPage