import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useConnectWalletMessagePopupContext } from '../../contexts/popups/ConnectWalletMessagePopupContextProvider'
import styles from './ConnectWalletMessagePopup.module.scss'

function ConnectWalletMessagePopup() {
    const [ t, i18n ] = useTranslation()
    const connectWalletMessagePopupContext = useConnectWalletMessagePopupContext()

    const onClose = () => {
        connectWalletMessagePopupContext?.show(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.parentWrapper}>
                <div className={styles.contentWrapper}>
                    <div className={styles.description2}>
                        <Trans i18nKey='metaMaskHowToConnect.description2'></Trans>
                    </div>
                    <div className={styles.closeButtonWrapper}><input type="button" className={styles.closeButton} value={t<string>('homepage.subscriptionPopup.close')} onClick={()=> { onClose() }}/></div>
                </div>
            </div>
        </div>
    )
}

export default ConnectWalletMessagePopup
