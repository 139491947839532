import React from 'react'
import styles from './DrawItemSectionPack2.module.scss'
import item1 from './images/Banner_Pack_Series2_News.png'
import bgPlayerMobile from './images/bg_player_mobile.png'
import bgPlayer from './images/bg_player.png'
import { Trans } from 'react-i18next'

function DrawItemSectionPack2() {
    return (
        <div className={styles.bgWrapper}>
            <img className={styles.itemImage} src={item1}/>
            <div className={styles.wrapper}>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleSection}>
                        <div className={styles.title}>
                            <Trans i18nKey='drawItemSectionPack2.title.part1'></Trans>
                        </div>
                        <div className={styles.title2}>
                            <Trans i18nKey='drawItemSectionPack2.title.part2'></Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrawItemSectionPack2
