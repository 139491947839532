import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import { usePopupUpdateContext } from '../../contexts/PopupContextProvider'
import styles from './DrawItemPopup.module.scss'
import collectionBoxImg from './images/collection_box.png'
import ProgressBar from '../../common/progressBar/ProgressBar'
import Loading from '../../common/loading/Loading'
import DotLoading from '../../common/dotLoading/DotLoading'
import { OpenSeaAsset, OpenSeaAssetQuery } from 'opensea-js/lib/types'
import openBoxMp4 from "./images/RCFC_2021_STD_P_S1_OPEN.mp4"
import GenericButton from '../../common/genericButton/GenericButton'
import Price from '../../common/price/Price'
import HowTo from '../../common/howTo/HowTo'
import Terms from '../../common/termsAndConditions/termsAndConditions'
import {singlePrice, packPrice} from '../../AppSettings.json'
import {env, assetAddressMainnet, assetAddressRinkeby, awsXApiKey , assetOwnerMainnet , assetOwnerRinkeby} from '../../secrets.json'

function DrawItemPopup() {
    enum Progress {
        Idle,
        Transaction,
        TransactionDone,
        TransactionFailed,
        LuckyDraw,
        LuckyDrawDone,
        LuckyDrawFailed
    }

    const { t, i18n } = useTranslation()
    const history = useHistory()
    const openSeaContext = useOpenSeaContext()
    const appContext = useAppContext()
    const popupUpdateContext = usePopupUpdateContext()
    const [progress, setProgress] = useState<Progress>(Progress.Idle)
    const [targetItem, setTargetItem] = useState<OpenSeaAsset | undefined>(undefined)
    const [finalItemJson, setFinalItemJson] = useState<any | undefined>(undefined)
    const [termsChecked, setTermsChecked] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<any>("")
    const [successMsg, setSuccessMsg] = useState<any>("")
    const isEnvMainnet = (env == "mainnet")

    const getFinalItemDisplay = () => {
        if (finalItemJson) {
            if (finalItemJson.animation_url) {
                return (<div className={styles.display}>
                    <video className={styles.finalAnimation} src={finalItemJson?.animation_url} autoPlay={true} />
                </div>)
            } else {
                if (finalItemJson.image) {
                    return <div className={styles.display}><img className={styles.finalImage} src={finalItemJson.image}/></div>
                } else {
                    return <div></div>
                }
            }
        } else {
            return <div className={styles.loadingWrapper}><Loading width={128} height={128}/></div>
        }
    }

    useEffect(() => {
        async function onTargetAvailable() {
            if (targetItem) {
                console.log("On Item Available")

                try {
                    if (!appContext?.accounts) {
                        setErrorMsg(t<string>('Error.connectWalletError'))
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!openSeaContext) {
                        setErrorMsg(t<string>('Error.openSeaError'))
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (!targetItem.sellOrders) {
                        setErrorMsg(t<string>('Error.sellOrderArrayUndefined') + targetItem.tokenId)
                        setProgress(Progress.TransactionFailed)
                        return
                    }
                    if (targetItem.sellOrders.length <= 0) {
                        setErrorMsg(t<string>('Error.noSellOrder') + targetItem.tokenId)
                        setProgress(Progress.TransactionFailed)
                        luckyDraw()
                        return
                    }

                    console.log("Sell Orders: " + JSON.stringify(targetItem.sellOrders, null, 2))

                    var sellOrder = targetItem.sellOrders[0]
                    var isOrderFulfillable = await openSeaContext.isOrderFulfillable({
                        order: sellOrder,
                        accountAddress: appContext.accounts[0],
                        recipientAddress: appContext.accounts[0]
                    })

                    if (isOrderFulfillable) {

                        var orderResult = await openSeaContext.fulfillOrder({
                            order: sellOrder,
                            accountAddress: appContext.accounts[0]
                        })

                        console.log('Transaction Done: Order Result: ' + JSON.stringify(orderResult))
                        setProgress(Progress.TransactionDone)
                        luckyDraw()

                    } else {
                        setErrorMsg(t<string>('Error.orderNotFulfillableError') + targetItem.tokenId)
                        setProgress(Progress.TransactionFailed)
                    }

                } catch (e) {
                    console.log("Error " + JSON.stringify(e))
                    setErrorMsg(t<string>('Error.runtimeError') + targetItem.tokenId)
                    setProgress(Progress.TransactionFailed)
                }
            }
        }
        onTargetAvailable()
        return () => {
        }
    }, [targetItem])

    const luckyDraw = async () => {
        setProgress(Progress.LuckyDraw)
        setTimeout(() => {
            setProgress(Progress.LuckyDrawDone)
        }, 3000)
        if (targetItem) {
            try {
                if (isEnvMainnet) {
                    var result = await axios.put("https://api.hkfootballnft.com/updateowner", { "tokenId" : targetItem.tokenId } , {
                        headers : { 'X-Api-Key' : awsXApiKey }
                    })
                }
                else {
                    var result = await axios.put("https://api.hkfootballnft.com/metadata", { "tokenId" : targetItem.tokenId })
                }
                
                if (result.status == 200) {

                    try {
                        await axios.get("https://api.hkfootballnft.com/draw1/updatedata")
                    } catch(e) {
                        console.log("Refresh Sales Metadata runtime error")
                    }

                    try {
                        var url = isEnvMainnet ? "https://api.opensea.io/api/v1/asset/" : "https://testnets-api.opensea.io/api/v1/asset/"
                        await axios.get(url + targetItem.tokenAddress + "/" + targetItem.tokenId + "?force_update=true")

                    } catch(e) {
                        console.log("Refresh OpenSea Metadata runtime error")
                    }

                    try {
                        var jsonResult = await axios.get("https://api.hkfootballnft.com/json/" + targetItem.tokenId)
                        setFinalItemJson(jsonResult.data)
                    } catch(e) {
                        setErrorMsg(t<string>("Error.getJsonDataError"))
                        setProgress(Progress.LuckyDrawFailed)
                    }

                } else {
                    setErrorMsg(t<string>('Error.unCoverFailedError'))
                    setProgress(Progress.LuckyDrawFailed)
                }
            } catch(e) {
                    setErrorMsg(t<string>('Error.unCoverRuntimeError'))
                    setProgress(Progress.LuckyDrawFailed)
            }
        }
    }

    const reLuckyDraw = async () => {
        setErrorMsg("")
        luckyDraw()
    }

    const drawOne = async () => {
        console.log('draw one')
        var updatedData = false
        try {
            setProgress(Progress.Transaction)
            var result = await axios.get('https://api.hkfootballnft.com/draw1')
            console.log(" -- draw1 result -- ",result)
            if (result.data.Item == null){
                await axios.get("https://api.hkfootballnft.com/draw1/updatedata")
                result = await axios.get('https://api.hkfootballnft.com/draw1')
                console.log(" -- retry draw1 result -- ",result)
                updatedData = true;
            }
            if (result.data.Item == null){
                setErrorMsg(t<string>('Error.soldOutError'))
                setProgress(Progress.TransactionFailed)
                return
            }

            var item = result.data.Item
            var tokenAddress = isEnvMainnet ? assetAddressMainnet : assetAddressRinkeby
            var ownerAddress = isEnvMainnet ? assetOwnerMainnet : assetOwnerRinkeby
            var asset : OpenSeaAsset | undefined = undefined;
            if (item) {
                try{
                    asset = await openSeaContext?.api.getAsset({
                        tokenAddress: tokenAddress,
                        tokenId: item.token_id
                    })
                    if (asset == null || asset.sellOrders == null || asset.sellOrders.length == 0 || asset.owner.address != ownerAddress){
                        asset = undefined
                    }
                }
                catch(e) {
                    console.log("No order on opensea ", e);
                }

                if (!updatedData && !asset) {
                    try {
                        // Update and Retry as server give item which opensea dont have
                        await axios.get("https://api.hkfootballnft.com/draw1/updatedata")
                        result = await axios.get('https://api.hkfootballnft.com/draw1')
                        console.log(" -- opensea not match: retry draw1 result -- ",result)
                        item = result.data.Item
                        asset = await openSeaContext?.api.getAsset({
                            tokenAddress: tokenAddress,
                            tokenId: item.token_id
                        })
                    } catch(e) {
                        console.log("No order on opensea or server", e);
                    }
                }
            }

            if (asset && asset.sellOrders && asset.sellOrders.length > 0){
                setTargetItem(asset)
            }
            else {
                setErrorMsg(t<string>('Error.soldOutError'))
                setProgress(Progress.TransactionFailed)
            }

        } catch (e) {
            setErrorMsg(t<string>('Error.runTimeError'))
            setProgress(Progress.TransactionFailed)
            console.log("error " + JSON.stringify(e, null, 2))
        }
    }

    const onClose = () => {
        popupUpdateContext?.showDrawItemPopup(false)
    }

    const gotoHome = () => {
        popupUpdateContext?.showDrawItemPopup(false)
        history.push("/")
    }

    const gotoCollection = () => {
        popupUpdateContext?.showDrawItemPopup(false)
        history.push("/collection")
    }

    const tryAgain = () => {
        console.log('tryAgain')
        popupUpdateContext?.showDrawItemPopup(false)
    }

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.closeButtonWrapper}>
                <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
            </div>
            <div id="drawItemPopup" className={styles.popupWrapper}>
                {progress == Progress.Transaction && <div className={styles.progressBarWrapper}><ProgressBar step={0}/></div>}
                {progress == Progress.TransactionDone && <div className={styles.progressBarWrapper}><ProgressBar step={1}/></div>}
                {progress == Progress.LuckyDraw && <div className={styles.progressBarWrapper}><ProgressBar step={2}/></div>}
                {progress == Progress.LuckyDrawDone && <div className={styles.progressBarWrapper}><ProgressBar step={3}/></div>}
                <div className={styles.contentWrapper}>
                    {progress == Progress.Idle && <div className={styles.idleWrapepr}>
                    <div className={styles.descriptionWrapper}>
                        <div className={styles.title}>
                            <Trans i18nKey="drawItemPopup.title"></Trans>
                        </div>
                        <div className={styles.itemImage}><img src={collectionBoxImg}/></div>
                        <div className={styles.name}><Trans i18nKey='drawItemPopup.name'></Trans></div>
                        <div className={styles.line}></div>
                        <div className={styles.price}><Trans i18nKey='drawItemPopup.total'></Trans> <Price eth={singlePrice}/></div>
                        <div className={styles.terms}><input className={styles.checkbox} type="checkbox" onClick={(value: any) =>{ setTermsChecked(!termsChecked) }}/> 
                            <Terms text={t<string>('drawItemPopup.terms')} />
                        </div>
                        <div className={styles.ctaSection}>
                            <GenericButton value={t<string>('drawItemPopup.ctaButton.value')} onClick={()=>{ drawOne() }} enabled={termsChecked} />
                        </div>
                        <div className={styles.howToPurchase}>
                            <HowTo text={t<string>('drawItemPopup.howToPurchase')} link={t<string>('mediumLinks.howToBuy')} ></HowTo>
                        </div>
                    </div>

                    </div>}
                    {progress == Progress.Transaction && <div className={styles.transactionWrapper}>
                        <div  className={styles.detailsWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img className={styles.userImage}/>
                            </div>
                            <hr className={styles.horizontalLine}/>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.transaction.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.transaction.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionDone && <div className={styles.transactionWrapper}>
                        <div className={styles.imageWrapper}>
                            <div>
                                <img className={styles.userImage}/>
                            </div>
                            <hr className={styles.horizontalLine}/>
                            <div>
                                <img src={collectionBoxImg}/>
                            </div>
                        </div>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.transactionCompleted.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.transactionCompleted.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <DotLoading width={16} height={16} borderRadius={16}/>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.TransactionFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.transactionFailed.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.transactionFailed.description"></Trans>
                            </div>
                            <div className={styles.error}>
                                <div className={styles.errorMsg}><Trans i18nKey="drawItemPopup.transactionFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomeWrapper}>
                                    <GenericButton hollow={true} value={t<string>('drawItemPopup.transactionFailed.gotoHome')} onClick={()=>{ gotoHome() }} />
                                    {/* <input className={styles.gotoHome} type="button"  value={t<string>('drawItemPopup.transactionFailed.gotoHome')} onClick={() => {gotoHome()}}/> */}
                                </div>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton value={t<string>('drawItemPopup.transactionFailed.tryAgain')} onClick={()=>{ tryAgain() }} />
                                    {/* <input className={styles.tryAgain} type="button"  value={t<string>('drawItemPopup.transactionFailed.tryAgain')} onClick={ () => {tryAgain()}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDraw && <div className={styles.luckyDrawWrapper}>
                        <video className={styles.openBox} src={openBoxMp4} autoPlay={true}/>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.luckyDraw.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.luckyDraw.description"></Trans>
                            </div>
                            <div className={styles.loading}>
                                <div className={styles.loadingDot}></div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawDone && <div className={styles.luckyDrawDoneWrapper}>
                        {getFinalItemDisplay()}
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.luckyDrawDone.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.luckyDrawDone.description.part1"></Trans>
                                {finalItemJson && finalItemJson.name}
                                <Trans i18nKey="drawItemPopup.luckyDrawDone.description.part2"></Trans>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.gotoHomePageWrapper}>
                                    <GenericButton hollow={true} value={t<string>('drawItemPopup.luckyDrawDone.gotoHome')} onClick={()=>{ gotoHome() }} />
                                    {/* <input className={styles.gotoHomePageButton} type="button"  value={t<string>('drawItemPopup.luckyDrawDone.gotoHome')} onClick={() => {gotoHome()}}/> */}
                                </div>
                                <div className={styles.collectionWrapper}>
                                    <GenericButton value={t<string>('drawItemPopup.luckyDrawDone.gotoCollection')} onClick={()=>{ gotoCollection() }} />
                                    {/* <input className={styles.collectionButton} type="button"  value={t<string>('drawItemPopup.luckyDrawDone.gotoCollection')} onClick={ () => {gotoCollection()}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {progress == Progress.LuckyDrawFailed && <div className={styles.transactionFailedWrapper}>
                        <div className={styles.instructionWrapper}>
                            <div className={styles.title}>
                                <Trans i18nKey="drawItemPopup.luckyDrawFailed.title"></Trans>
                            </div>
                            <div className={styles.description}>
                                <Trans i18nKey="drawItemPopup.luckyDrawFailed.description"></Trans>
                            </div>
                            <div className={styles.error}>
                                <div className={styles.errorMsg}><Trans i18nKey="drawItemPopup.luckyDrawFailed.error"></Trans> : {errorMsg}</div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <div className={styles.tryAgainWrapper}>
                                    <GenericButton value={t<string>('drawItemPopup.luckyDrawFailed.tryAgain')} onClick={()=>{ reLuckyDraw() }} />
                                    {/* <input className={styles.tryAgain} type="button" value={t<string>('drawItemPopup.luckyDrawFailed.tryAgain')} onClick={ () => { reLuckyDraw() }}/> */}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default DrawItemPopup
