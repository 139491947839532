import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './BidItemSection.module.scss'
import alertCircle from '../images/alert-circle.png'
import GenericButton from '../../../common/genericButton/GenericButton'
import lcMp4 from '../../../images/RCFC_2021_BEST_1_LS.mp4'
import { isMobile } from 'react-device-detect'
import BidItemImage from '../../../common/bidItemImage/BidItemImage'
import HowTo from '../../../common/howTo/HowTo'
import DateCounter from '../../../common/dateCounter/DateCounter'
import {IsExpired} from '../../../common/dateCounter/DateCounter'
import Price from '../../../common/price/Price'
import {bid_start_month, bid_start_day, bid_start_hour, bid_start_minute } from '../../../AppSettings.json'
import {bid_end_month, bid_end_day, bid_end_hour, bid_end_minute, bidEnabled } from '../../../AppSettings.json'
import { useAppContext, useAppUpdateContext } from '../../../contexts/AppContextProvider'


function BidItemSection() {
    const appContext = useAppContext()
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const [ isStarted , setIsStarted ] = useState<boolean>(true)
    const [ isEnded , setIsEnded ] = useState<boolean>(true)
    const [ isUpdate , setUpdate] = useState<boolean>(true)

    const gotoBidItemDetailsPage = () => {
        history.push('/biditem')
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        setInterval(() => { 
            let isStart = IsExpired( {year:2021,  month:bid_start_month-1, day:bid_start_day , hour:bid_start_hour,  minute:bid_start_minute , second:0} );
            let isExpired = IsExpired( {year:2021,  month:bid_end_month-1, day:bid_end_day , hour:bid_end_hour,  minute:bid_end_minute , second:0} );
            setIsStarted(isStart);
            setIsEnded(isExpired);  
        } , 1000 ) 
    } , [] )

 return (
        <div className={styles.bgWrapper}>
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.logoParentWrapper}>
                    <BidItemImage/>
                </div>
                <div className={styles.description}><Trans i18nKey='bidItemSection.description'></Trans></div>
                    {!isStarted  && <div>
                        <DateCounter year={2021} month={bid_start_month-1} day={bid_start_day} hour={bid_start_hour} minute={bid_start_minute} second={0}/>
                    </div> }
                    {isStarted && !isEnded  && <div>
                        <DateCounter year={2021} month={bid_end_month-1} day={bid_end_day} hour={bid_end_hour} minute={bid_end_minute} second={0}/>
                    </div> }
                    {!isMobile && <div className={styles.ctaButtonWrapper}>
                    <div className={styles.ctaButton}>
                        <GenericButton enabled={bidEnabled && isStarted && !isEnded} center={false} value={t<string>('bidItemSection.ctaButton.title')} onClick={()=>{ gotoBidItemDetailsPage() }}/>
                    </div>
                    {appContext != null && <Price eth={appContext?.bidPrice}/>}
                </div>}
                <div className={styles.howToWrapper}><HowTo text={t<string>('bidItemSection.howToBid')} link={t<string>('mediumLinks.howToBuy')} /></div>
            </div>
            <div className={styles.videoWrapper}>
                <video className={styles.videoContent} src={lcMp4} autoPlay={true} loop={true} muted width={480} height={480}/>
            </div>
        </div>
        </div>
    )
}

export default BidItemSection
