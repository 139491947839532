import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useContext, useState } from 'react'

export type Draw3ItemsPopupContent = {
    isShowing: boolean,
    show: (popupContent: boolean) => void
}

const Draw3ItemsPopupContext = React.createContext<Draw3ItemsPopupContent | undefined>(undefined)

export function useDraw3ItemsPopupContext() {
    return useContext(Draw3ItemsPopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function Draw3ItemsPopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<Draw3ItemsPopupContent>({
        isShowing: false,
        show: (shouldShow: boolean) => {
            const targetElement = document.querySelector('body');
            if (shouldShow) {
                if (targetElement) {
                    disableBodyScroll(targetElement);
                }
            } else {
                if (targetElement) {
                    enableBodyScroll(targetElement);
                }
            }
            setPopupContent(
                {...popupContent, isShowing: shouldShow}
            )
        }
    })

    return (
        <Draw3ItemsPopupContext.Provider value={popupContent}>
            {props.children}
        </Draw3ItemsPopupContext.Provider>
    )
}

export default Draw3ItemsPopupContextProvider