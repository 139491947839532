import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useContext, useState } from 'react'

export type ConnectWalletPopupContent = {
    isShowing: boolean,
    show: (popupContent: boolean) => void
}

const ConnectWalletPopupContext = React.createContext<ConnectWalletPopupContent | undefined>(undefined)

export function useConnectWalletPopupContext() {
    return useContext(ConnectWalletPopupContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function ConnectWalletPopupContextProvider(props: Props) {
    const [popupContent, setPopupContent] = useState<ConnectWalletPopupContent>({
        isShowing: false,
        show: (shouldShow: boolean) => {
            if (shouldShow) {
                const targetElement = document.querySelector('body');
                if (targetElement) {
                    disableBodyScroll(targetElement);
                }
            } else {
                const targetElement = document.querySelector('body');
                if (targetElement) {
                    enableBodyScroll(targetElement);
                }
            }
            setPopupContent(
                {...popupContent, isShowing: shouldShow}
            )
        }
    })

    return (
        <ConnectWalletPopupContext.Provider value={popupContent}>
            {props.children}
        </ConnectWalletPopupContext.Provider>
    )
}

export default ConnectWalletPopupContextProvider
