import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './WrapEthPopup.module.scss'
import wethIcon from '../../images/weth-icon.png'
import GenericButton from '../../common/genericButton/GenericButton'
import { useOpenSeaContext } from '../../contexts/OpenSeaContextProvider'
import { useAppContext } from '../../contexts/AppContextProvider'
import { useConnectWalletPopupContext } from '../../contexts/popups/ConnectWalletPopupContextProvider'
import { usePopupUpdateContext } from '../../contexts/PopupContextProvider'
import { useLoadingPopupContext } from '../../contexts/popups/LoadingPopupContextProvider'
import { useWrapEthPopupContext } from '../../contexts/popups/WrapEthPopupContextProvider'
import MessagePopup from '../messagePopup/MessagePopup'
import { useMessagePopupContext } from '../../contexts/popups/MessagePopupContextProvider'
import Loading from '../../common/loading/Loading'
import Terms from '../../common/termsAndConditions/termsAndConditions'

function WrapEthPopup() {
    enum Progress {
        Idle,
        Transaction,
        TransactionInProgress,
        TransactionDone,
        TransactionFailed
    }

    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key :any , value: any) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      };

    const {t, i18n} = useTranslation()
    const appContext = useAppContext()
    const openSeaContext = useOpenSeaContext()
    const connectWalletPopupContext = useConnectWalletPopupContext()
    const loadingPopupContext = useLoadingPopupContext()
    const wrapEthPopupContext = useWrapEthPopupContext()
    const messagePopupContext = useMessagePopupContext()
    const [ethToWrap, setEthToWrap] = useState(0)
    const [termsChecked, setTermsChecked] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [progress, setProgress] = useState<Progress>(Progress.Idle)

    const onChangeValue = async (e: any) => {
        setEthToWrap(e.target.value)
    }

    const onClose = () => {
        console.log("onClose")
        wrapEthPopupContext?.show(false)
    }

    const wrap = async () => {
        try {
            if (appContext?.accounts) {
                console.log("eth to wrap " + ethToWrap)
                setProgress(Progress.TransactionInProgress)
                var result = await openSeaContext?.wrapEth(
                    {
                        amountInEth: ethToWrap,
                        accountAddress: appContext.accounts[0]
                    }
                )
                console.log(JSON.stringify(result, null, 2))
                setProgress(Progress.TransactionDone)
            } else {
                connectWalletPopupContext?.show(true)
            }
        } catch (e) {
            setProgress(Progress.TransactionFailed)
            setErrorMsg(t<string>('wrapEthPopup.error.notEnoughFund'))
        } finally {

        }
    }

    return (
        <div className={styles.bgWrapper}>
            {progress == Progress.Idle && <div className={styles.popupWrapper}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.progressBid}>
                        <div className={styles.title}>
                            <Trans i18nKey="wrapEthPopup.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="wrapEthPopup.description"></Trans>
                        </div>
                        <div className={styles.priceSection}>
                            <div className={styles.priceWrapper}>
                                <div className={styles.priceIconWrapper}>
                                    <div className={styles.priceTitle}><Trans i18nKey="wrapEthPopup.price"></Trans></div>
                                    <div className={styles.priceIcon}>
                                        <img src={wethIcon}/>
                                        <div className={styles.iconName}>WETH</div>
                                    </div>
                                </div>
                                <input className={styles.wethInput} type="number" onChange={(e) => { onChangeValue(e) }}/>
                            </div>
                        </div>
                        <div className={styles.termsSection}>
                            <input className={styles.checkBox} type="checkBox" onClick={()=>{ setTermsChecked(!termsChecked) }}/> 
                            <Terms text={t<string>('wrapEthPopup.terms')} />
                            
                        </div>
                        <div className={styles.ctaSection}>
                            <GenericButton value={t<string>('wrapEthPopup.ctaButton')} onClick={()=>{ wrap() }} enabled={termsChecked} />
                        </div>
                        <div className={styles.howToBidSection}>
                        </div>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionInProgress && <div className={styles.inProgressWrapper}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.progressBid}>
                        <div className={styles.title}>
                            <Trans i18nKey="wrapEthPopup.transactionInProgress.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="wrapEthPopup.transactionInProgress.description"></Trans>
                        </div>
                        <Loading width={128} height={128}/>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionDone && <div className={styles.transactionDone}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.progressBid}>
                        <div className={styles.title}>
                            <Trans i18nKey="wrapEthPopup.transactionDone.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="wrapEthPopup.transactionDone.description"></Trans>
                        </div>
                    </div>
                </div>
            </div>}
            {progress == Progress.TransactionFailed && <div className={styles.transactionFailed}>
                <div className={styles.closeButtonWrapper}>
                    <input className={styles.closeButton} type="button" onClick={() => {onClose()}} value={t<string>('wrapEthPopup.closeButton')}/>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.progressBid}>
                        <div className={styles.title}>
                            <Trans i18nKey="wrapEthPopup.transactionFailed.title"></Trans>
                        </div>
                        <div className={styles.description}>
                            <Trans i18nKey="wrapEthPopup.transactionFailed.description"></Trans> {errorMsg}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default WrapEthPopup
