import React, { useContext, useState } from 'react'
import Web3 from 'web3'

export type Web3Content = {
    web3: Web3 | undefined,
    setWeb3: (web3: any) => void
}

const Web3Context = React.createContext<Web3Content | undefined>(undefined)

export function useWeb3Context() {
    return useContext(Web3Context)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function Web3ContextProvider(props: Props) {
    const [Web3Content, setWeb3Content] = useState<Web3Content>({
        web3: undefined,
        setWeb3: (web3: any) => {
            setWeb3Content(
                {...Web3Content, web3: web3}
            )
        }
    })

    return (
        <Web3Context.Provider value={Web3Content}>
            {props.children}
        </Web3Context.Provider>
    )
}

export default Web3ContextProvider
