import React from 'react'
import styles from './MobileAlertView.module.scss'
import { Trans, useTranslation } from 'react-i18next'

function MobileAlertView() {
    const { t, i18n } = useTranslation()
    return (
        <div className={styles.bgWrappper}>
            <div>
                <Trans i18nKey='mobileAlertView.title'></Trans>
            </div>
            <div>
                <Trans i18nKey='mobileAlertView.description'></Trans>
            </div>
        </div>
    )
}

export default MobileAlertView
