import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import LandingPage from './pages/landingPage/LandingPage';
import PopupContextProvider from './contexts/PopupContextProvider';
import Web3ContextProvider from './contexts/Web3ContextProvider';
import PortisContextProvider from './contexts/wallets/PortisContextProvider';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LandingPage></LandingPage>}>
      <Web3ContextProvider>
        <PortisContextProvider>
          <PopupContextProvider>
              <App />
          </PopupContextProvider>
        </PortisContextProvider>
      </Web3ContextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
