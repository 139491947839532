import styles from "./DotLoading.module.scss"

function DotLoading({width, height, borderRadius}: {width: number, height: number, borderRadius: number}) {
    const dotStyle = {
        width: width,
        height: height,
        borderRadius: borderRadius,
    }

    return (
        <div className={styles.wrapper}>
            <div style={dotStyle} className={styles.dot1}></div>
            <div style={dotStyle} className={styles.dot2}></div>
            <div style={dotStyle} className={styles.dot3}></div>
        </div>
    )
}

export default DotLoading
