import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './App.module.scss'
import PopupContextProvider, { usePopupContext } from './contexts/PopupContextProvider';
import Footer from './footer/Footer';
import HomePage from './pages/homepage/HomePage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import OpenSeaContextProvider from './contexts/OpenSeaContextProvider';
import BidItemSection from './pages/homepage/bidItemSection/BidItemSection';
import DrawItemSection from './pages/homepage/drawItemSection/DrawItemSection';
import DrawItemSectionPack2 from './pages/homepage/drawItemSectionPack2/DrawItemSectionPack2';
import DrawItemDetailsPage from './pages/drawItemDetailsPage/DrawItemDetailsPage';
import WalletPage from './pages/walletPage/WalletPage';
import FaqPage from './pages/faqPage/FaqPage';
import AppContextProvider from './contexts/AppContextProvider';
import LoadingPopup from './popups/loadingPopup/LoadingPopup';
import DrawItemPopup from './popups/drawItemPopup/DrawItemPopup';
import ConnectWalletPopup from './popups/connectWalletPopup/ConnectWalletPopup';
import { useConnectWalletPopupContext } from './contexts/popups/ConnectWalletPopupContextProvider';
import { useConnectWalletMessagePopupContext } from './contexts/popups/ConnectWalletMessagePopupContextProvider';
import ConnectWalletMessagePopup from './popups/connectWalletMessagePopup/ConnectWalletMessagePopup';
import CollectionPage from './pages/collectionPage/CollectionPage';
import BidItemDetailsPage from './pages/bidItemDetailsPage/BidItemDetailsPage';
import { useDraw3ItemsPopupContext } from './contexts/popups/Draw3ItemsPopupContextProvider';
import Draw3ItemsPopup from './popups/drawItems/draw3ItemsPopup/Draw3ItemsPopup';
import { usePlaceBidPopupContext } from './contexts/popups/PlaceBidPopupContextProvider';
import { useWrapEthPopupContext } from './contexts/popups/WrapEthPopupContextProvider';
import WrapEthPopup from './popups/wrapEthPopup/WrapEthPopup';
import PlaceBidPopup from './popups/placeBidPopup/PlaceBidPopup';
import { useLoadingPopupContext } from './contexts/popups/LoadingPopupContextProvider';
import { useMessagePopupContext } from './contexts/popups/MessagePopupContextProvider';
import { useProbabilityPopupContext } from './contexts/popups/ProbabilityPopupContextProvider';
import MessagePopup from './popups/messagePopup/MessagePopup';
import ProbabilityPopup from './popups/probabilityPopup/ProbabilityPopup';
import HowItWorksSection from './pages/homepage/howItWorksSection/HowItWorksSection';

function App() {
  const { t, i18n } = useTranslation()
  const connectWalletPopupContext = useConnectWalletPopupContext()
  const connectWalletMessagePopupContext = useConnectWalletMessagePopupContext()
  const popupContext = usePopupContext()
  const draw3ItemsPopupContext = useDraw3ItemsPopupContext()
  const placeBidPopupContext = usePlaceBidPopupContext()
  const wrapEthPopupContext = useWrapEthPopupContext()
  const loadingPopupContext = useLoadingPopupContext()
  const messagePopupContext = useMessagePopupContext()
  const probabilityPopupContext = useProbabilityPopupContext()

  window.location.replace("https://hkfootballnft.com");

  return (
    <Router>
    <div className={styles.app}>
      <header className={styles.appHeader}>
      <AppContextProvider>
        <OpenSeaContextProvider>
            <Switch>
              <Route exact path="/">
                  <HomePage></HomePage>
                  <BidItemSection></BidItemSection>
                  <div className={styles.description2}><Trans i18nKey='homepage.description2'></Trans></div>
                  <DrawItemSection></DrawItemSection>
                  <div className={styles.description3}><Trans i18nKey='homepage.description3'></Trans></div>
                  <DrawItemSectionPack2></DrawItemSectionPack2>
                  <HowItWorksSection></HowItWorksSection>
                  <Footer></Footer>
              </Route>
              <Route exact path="/en">
                  <PrivacyPolicy></PrivacyPolicy>
                  <Footer></Footer>
              </Route>
              <Route exact path="/wallet">
                <div className={styles.bgWrapper}>
                  <WalletPage/>
                </div>
                <Footer></Footer>
              </Route>
              <Route exact path="/collection">
                <div className={styles.bgWrapper}>
                  <CollectionPage/>
                </div>
                <Footer></Footer>
              </Route>
              <Route exact path="/biditem">
                <div className={styles.bgWrapper}>
                  <BidItemDetailsPage/>
                </div>
                <Footer></Footer>
              </Route>
              <Route exact path="/drawitem">
                <div className={styles.bgWrapper}>
                  <DrawItemDetailsPage/>
                </div>
                <Footer></Footer>
              </Route>
              <Route exact path="/faq">
                <div className={styles.bgWrapper}>
                  <FaqPage/>
                </div>
                <Footer></Footer>
              </Route>
            </Switch>
            <div>{popupContext?.shouldShowLoadingPopup && <LoadingPopup/>}</div>
            <div>{popupContext?.shouldShowDrawItemPopup && <DrawItemPopup/>}</div>
            <div>{draw3ItemsPopupContext?.isShowing && <Draw3ItemsPopup/>}</div>
            <div>{placeBidPopupContext?.isShowing && <PlaceBidPopup/>}</div>
            <div>{wrapEthPopupContext?.isShowing && <WrapEthPopup/>}</div>
            <div>{connectWalletPopupContext?.isShowing && <ConnectWalletPopup/>}</div>
            <div>{connectWalletMessagePopupContext?.isShowing && <ConnectWalletMessagePopup/>}</div>
            <div>{loadingPopupContext?.isShowing && <LoadingPopup/>}</div>
            <div>{messagePopupContext?.isShowing && <MessagePopup/>}</div>
            <div>{probabilityPopupContext?.isShowing && <ProbabilityPopup/>}</div>
          </OpenSeaContextProvider>
        </AppContextProvider>
      </header>
    </div>
    </Router>
  );
}

export default App;