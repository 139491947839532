import React, { useContext, useEffect, useState } from 'react'
import Web3 from 'web3'
import { Network, OpenSeaPort } from 'opensea-js'
import {env, portisAppid, openseaAppid, alchemyRinkebyApi, alchemyMainnetApi } from '../secrets.json'

export type OpenSeaUpdateContent = {
    setOpenSeaContent: (openSeaPort: OpenSeaPort | undefined) => void
}

const OpenSeaContext = React.createContext<OpenSeaPort | undefined>(undefined)
const OpenSeaUpdateContext = React.createContext<OpenSeaUpdateContent | undefined>(undefined)

export function useOpenSeaContext () {
    return useContext(OpenSeaContext)
}

export function useOpenSeaUpdateContext () {
    return useContext(OpenSeaUpdateContext)
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

function OpenSeaContextProvider(props: Props) {
    const isEnvMainnet = (env == "mainnet")
    const [openSeaPort, setOpenSeaPort] = useState<OpenSeaPort | undefined>(undefined)

    const setOpenSeaContent = (openSeaPort: OpenSeaPort | undefined) => {
        setOpenSeaPort(openSeaPort)
    }

    const [openSeaUpdateContent, setOpenSeaUpdateContent] = useState<OpenSeaUpdateContent | undefined>({
        setOpenSeaContent: setOpenSeaContent
    })

    const initProvider = () => {
        if (!openSeaPort) {
            var provider = new Web3.providers.HttpProvider(isEnvMainnet? alchemyMainnetApi : alchemyRinkebyApi)
            if (provider) {
                try {
                    var newOpenSeaPort : OpenSeaPort = new OpenSeaPort(provider, {
                        networkName: isEnvMainnet? Network.Main : Network.Rinkeby,
                        // apiKey: opensea_appid
                    })
                    setOpenSeaPort(newOpenSeaPort)
                } catch (e) {
                    console.log('e ' + JSON.stringify(e))
                }
            }
        }
    }
    initProvider();

    return (
        <OpenSeaContext.Provider value={openSeaPort}>
            <OpenSeaUpdateContext.Provider value={openSeaUpdateContent}>
                {props.children}
            </OpenSeaUpdateContext.Provider>
        </OpenSeaContext.Provider>
    )
}

export default OpenSeaContextProvider